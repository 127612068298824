import { useTranslation } from "react-i18next";

export const Projects = () => {
    const { t } = useTranslation();

    return [
        {
            "year": "113",
            "title": t("page.projects.projectList.titles.113_2"),
            "position": t("page.projects.projectList.pi"),
            "time": "2024/08/01 ~ 2027/07/31",
            "expenditure": "?????",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "113",
            "title": t("page.projects.projectList.titles.113_1"),
            "position": t("page.projects.projectList.pi"),
            "time": "2024/08/01 ~ 2027/07/31",
            "expenditure": "?????",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "112",
            "title": t("page.projects.projectList.titles.112_1"),
            "position": t("page.projects.projectList.pi"),
            "time": "2023/08/01 ~ 2026/07/31",
            "expenditure": "?????",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "110",
            "title": t("page.projects.projectList.titles.110_2"),
            "position": t("page.projects.projectList.pi"),
            "time": "2021/08/01 ~ 2024/07/31",
            "expenditure": "?????",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "110",
            "title": t("page.projects.projectList.titles.110_1"),
            "position": t("page.projects.projectList.pi"),
            "time": "2021/05/01 ~ 2022/04/30",
            "expenditure": "?????",
            "sponsor": t("page.projects.projectList.itri")
        },
        {
            "year": "109",
            "title": t("page.projects.projectList.titles.109_3"),
            "position": t("page.projects.projectList.pi"),
            "time": "2020/08/01 ~ 2023/07/31",
            "expenditure": "?????",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "109",
            "title": t("page.projects.projectList.titles.109_2"),
            "position": t("page.projects.projectList.pi"),
            "time": "2020/08/01 ~ 2023/07/31",
            "expenditure": "?????",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "109",
            "title": t("page.projects.projectList.titles.109_1"),
            "position": t("page.projects.projectList.pi"),
            "time": "2020/08/01 ~ 2023/07/31",
            "expenditure": "?????",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "107",
            "title": t("page.projects.projectList.titles.107_1"),
            "position": t("page.projects.projectList.pi"),
            "time": "2018/08/01 ~ 2021/07/31",
            "expenditure": "?????",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "106",
            "title": t("page.projects.projectList.titles.106_2"),
            "position": t("page.projects.projectList.pi"),
            "time": "2017/08/01 ~ 2020/07/31",
            "expenditure": "?????",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "106",
            "title": t("page.projects.projectList.titles.106_1"),
            "position": t("page.projects.projectList.pi"),
            "time": "2017/08/01 ~ 2020/07/31",
            "expenditure": "?????",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "104",
            "title": t("page.projects.projectList.titles.104_1"),
            "position": t("page.projects.projectList.pi"),
            "time": "2015/08/01 ~ 2018/07/31",
            "expenditure": "?????",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "103",
            "title": t("page.projects.projectList.titles.103_1"),
            "position": t("page.projects.projectList.pi"),
            "time": "2014/08/01 ~ 2017/07/31",
            "expenditure": "?????",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "101",
            "title": t("page.projects.projectList.titles.101_3"),
            "position": t("page.projects.projectList.pi"),
            "time": "2012/08/01 ~ 2015/07/31",
            "expenditure": "?????",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "101",
            "title": t("page.projects.projectList.titles.101_2"),
            "position": t("page.projects.projectList.pi"),
            "time": "2012/08/01 ~ 2015/07/31",
            "expenditure": "?????",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "101",
            "title": t("page.projects.projectList.titles.101_1"),
            "position": t("page.projects.projectList.pi"),
            "time": "2011/08/01 ~ 2013/02/28",
            "expenditure": "?????",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "99",
            "title": t("page.projects.projectList.titles.99_2"),
            "position": t("page.projects.projectList.pi"),
            "time": "2010/08/01 ~ 2011/07/31",
            "expenditure": "?????",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "99",
            "title": t("page.projects.projectList.titles.99_1"),
            "position": t("page.projects.projectList.co_pi"),
            "time": "2010/08/01 ~ 2011/07/31",
            "expenditure": "19,998,000",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "98",
            "title": t("page.projects.projectList.titles.98_4"),
            "position": t("page.projects.projectList.co_pi"),
            "time": "2009/12/01 ~ 2012/11/30",
            "expenditure": "3,015,000",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "98",
            "title": t("page.projects.projectList.titles.98_3"),
            "position": t("page.projects.projectList.co_pi"),
            "time": "2009/08/01 ~ 2010/07/31",
            "expenditure": "19,989,000",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "98",
            "title": t("page.projects.projectList.titles.98_2"),
            "position": t("page.projects.projectList.pi"),
            "time": "2009/08/01 ~ 2012/07/31",
            "expenditure": "2,757,000",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "98",
            "title": t("page.projects.projectList.titles.98_1"),
            "position": t("page.projects.projectList.co_pi"),
            "time": "2009/08/01 ~ 2011/07/31",
            "expenditure": "1,332,000",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "97",
            "title": t("page.projects.projectList.titles.97_2"),
            "position": t("page.projects.projectList.co_pi"),
            "time": "2008/12/01 ~ 2009/07/31",
            "expenditure": "16,553,000",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "97",
            "title": t("page.projects.projectList.titles.97_1"),
            "position": t("page.projects.projectList.co_pi"),
            "time": "2008/01/01 ~ 2009/03/31",
            "expenditure": "7,195,000",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "96",
            "title": t("page.projects.projectList.titles.96_2"),
            "position": t("page.projects.projectList.pi"),
            "time": "2007/08/01 ~ 2009/07/31",
            "expenditure": "1,736,000",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "96",
            "title": t("page.projects.projectList.titles.96_1"),
            "position": t("page.projects.projectList.co_pi"),
            "time": "2007/08/01 ~ 2009/07/31",
            "expenditure": "1,260,000",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "95",
            "title": t("page.projects.projectList.titles.95_3"),
            "position": t("page.projects.projectList.co_pi"),
            "time": "2006/08/01 ~ 2007/07/31",
            "expenditure": "5,276,000",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "95",
            "title": t("page.projects.projectList.titles.95_2"),
            "position": t("page.projects.projectList.co_pi"),
            "time": "2006/08/01 ~ 2007/07/31",
            "expenditure": "773,000",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "95",
            "title": t("page.projects.projectList.titles.95_1"),
            "position": t("page.projects.projectList.pi"),
            "time": "2006/08/01 ~ 2007/09/30",
            "expenditure": "1,245,000",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "94",
            "title": t("page.projects.projectList.titles.94_3"),
            "position": t("page.projects.projectList.pi"),
            "time": "2005/08/01 ~ 2006/07/31",
            "expenditure": "825,000",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "94",
            "title": t("page.projects.projectList.titles.94_2"),
            "position": t("page.projects.projectList.co_pi"),
            "time": "2005/08/01 ~ 2006/07/31",
            "expenditure": "842,700",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "94",
            "title": t("page.projects.projectList.titles.94_1"),
            "position": t("page.projects.projectList.co_pi"),
            "time": "2005/05/01 ~ 2006/04/30",
            "expenditure": "4,795,900",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "93",
            "title": t("page.projects.projectList.titles.93_4"),
            "position": t("page.projects.projectList.co_pi"),
            "time": "2004/08/01 ~ 2005/70/31",
            "expenditure": "689,500",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "93",
            "title": t("page.projects.projectList.titles.93_3"),
            "position": t("page.projects.projectList.pi"),
            "time": "2004/08 ~ 2005/07",
            "expenditure": "705,700",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "93",
            "title": t("page.projects.projectList.titles.93_2"),
            "position": t("page.projects.projectList.co_pi"),
            "time": "2004/05 ~ 2005/04",
            "expenditure": "4,580,700",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "93",
            "title": t("page.projects.projectList.titles.93_1"),
            "position": t("page.projects.projectList.co_pi"),
            "time": "2004/05 ~ 2007/04",
            "expenditure": "2,282,300",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "92",
            "title": t("page.projects.projectList.titles.92_1"),
            "position": t("page.projects.projectList.pi"),
            "time": "2003/08 ~ 2004/07",
            "expenditure": "608,100",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "91",
            "title": t("page.projects.projectList.titles.91_2"),
            "position": t("page.projects.projectList.pi"),
            "time": "2002/08 ~ 2003/07",
            "expenditure": "711,800",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "91",
            "title": t("page.projects.projectList.titles.91_1"),
            "position": t("page.projects.projectList.co_pi"),
            "time": "2002/08 ~ 2003/12",
            "expenditure": "?",
            "sponsor": t("page.projects.projectList.moe")
        },
        {
            "year": "90",
            "title": t("page.projects.projectList.titles.90_2"),
            "position": t("page.projects.projectList.pi"),
            "time": "2001/08 ~ 2002/07",
            "expenditure": "575,600",
            "sponsor": t("page.projects.projectList.nstc")
        },
        {
            "year": "90",
            "title": t("page.projects.projectList.titles.90_1"),
            "position": t("page.projects.projectList.pi"),
            "time": "2001/08 ~ 2002/07",
            "expenditure": "170,000",
            "sponsor": t("page.projects.projectList.moe")
        }
    ]
}
