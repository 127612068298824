import { useTranslation } from "react-i18next";
export const AssistantData = () => {
    const { t } = useTranslation();

    return [
        {
            imgURL: "/images/members/assistant/team_misty.jpg",
            name: {
                chinese: "楊詠雲",
                english: "Yung Yun Yang"
            },
            officePhone: "(03)4227151 #35235",
            email: "mistyyang@cl.ncu.edu.tw",
            workContent: t("page.member.info.assistant.workContent")
        },
        {
            imgURL: "/images/members/assistant/team_shihhsun.jpg",
            name: {
                chinese: "范姜士燻",
                english: "Shih-Hsun Fan Chiang"
            },
            email: "squall.f@gmail.com",
            spec: t("page.member.info.assistant.spec"),
        },
    ]
}