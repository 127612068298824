import React, { useRef } from 'react';
import Layout from '../component/Layout';
import BackToTop from '../component/BackToTop';
import CardComponent from '../component/CardComponent';
import ResearchPage from '../component/ResearchPage';

import { ScienceEducation } from './research/science_education';
import { CreativeLearning } from './research/creative_learning';
import { GameBase } from './research/game_base';
import { AIapplication } from './research/ai_application';
import { Cscl } from './research/cscl';

const Research = () => {
  const sections = {
    scienceeducation: useRef(null),
    creativelearning: useRef(null),
    gamebase: useRef(null),
    aiapplication: useRef(null),
    cscl: useRef(null),
  };

  const navRef = useRef(null);

  const researchDirections = [
    { id: 'aiapplication', data: AIapplication(), icon: 'mdi:robot-outline', zh_title: '人工智慧應用', en_title: 'AI Application', color: '#EF5350', hoverColor: '#FF867C' },
    { id: 'scienceeducation', data: ScienceEducation(), icon: 'mdi:atom-variant', zh_title: '科學教育', en_title: 'Science Education', color: '#FB8C00', hoverColor: '#FFB74D' },
    { id: 'creativelearning', data: CreativeLearning(), icon: 'mdi:book-open-page-variant-outline', zh_title: '創造與學習', en_title: 'Creative & Learning', color: '#66BB6A', hoverColor: '#A5D6A7' },
    { id: 'gamebase', data: GameBase(), icon: 'mdi:gamepad-variant-outline', zh_title: '悅趣式學習', en_title: 'Game-based Learning', color: '#42A5F5', hoverColor: '#90CAF9' },
    { id: 'cscl', data: Cscl(), icon: 'mdi:account-group', zh_title: '電腦輔助合作學習', en_title: 'Computer Supported Collaborative Learning', color: '#AB47BC', hoverColor: '#CE93D8' },
  ];      

  const handleScroll = (id) => {
    sections[id].current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <Layout>
      <BackToTop />
      {/* 卡片區域容器 */}
      <div
        ref={navRef}
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', // 自動調整卡片列數
          gap: '20px', // 卡片之間的間距
          padding: '20px',
        }}
      >
        {researchDirections.map((direction) => (
          <CardComponent
            key={direction.id}
            icon={direction.icon}
            zh_title={direction.zh_title}
            en_title={direction.en_title}
            color={direction.color}
            hoverColor={direction.hoverColor}
            onClick={() => handleScroll(direction.id)}
          />
        ))}
      </div>

      {/* 各個研究方向內容 */}
      <div>
        {researchDirections.map((direction) => (
          <div key={direction.id} ref={sections[direction.id]} style={{ scrollMarginTop: '5vh' }}>
            <ResearchPage data={direction.data} />
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default Research;
