import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button, Tabs, Tab, Box, Tooltip, Menu, MenuItem } from "@mui/material";
import { Icon } from '@iconify/react';

const Header = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  // 分別為 "成員" 和 "研究方向" 創建獨立的 anchorEl
  const [memberAnchorEl, setMemberAnchorEl] = React.useState(null);
  const [languageText, setLanguageText] = React.useState(i18n.language === "zh" ? "English" : "中文");

  const handleMemberMenuClick = (event) => {
    setMemberAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setMemberAnchorEl(null);
  };

  const handleChange = (event, newValue) => {
    if (newValue === "member") {
      handleMemberMenuClick(event);
    }
    else {
      switch (newValue) {
        case "home":
          navigate("/");
          break;
        case "introduction":
          navigate("/about");
          break;
        case "publication":
          navigate("/publication");
          break;
        case "project":
          navigate("/project");
          break;
        case "research":
          navigate("/research");
          break;
        default:
          navigate("/");
          break;
      }
    }
  };

  const handleMemberItemClick = (page) => {
    console.log("/member/", page);
    navigate(`/members/${page}`);
    handleClose();
  };

  const handleLanguageTextChange = () => {
    if (i18n.language === "zh") {
      setLanguageText("English");
    } else {
      setLanguageText("中文");
    }
  }

  const handleLanguageChange = () => {
    const targetLanguage = i18n.language === "zh" ? "en" : "zh";
    i18n.changeLanguage(targetLanguage);
    handleLanguageTextChange();
  };

  return (
    <Box position={"sticky"} top={0} sx={{ zIndex: 2, borderBottom: 1, borderColor: "#364f3b", backgroundColor: "#f5f5f5", height: "10vh", display: "flex", alignItems: "center" }}>
      <Tabs
        onChange={handleChange}
        aria-label="lab API tabs example"
        variant="scrollable"
        scrollButtons="auto"
        textColor="#364f3b"
        style={{ maxWidth: "90vw" }}
      >
        <Tooltip title={t("component.Header.backToHome")}>
          <Tab icon={<img alt="test avatar" src="/images/LabIcon/linkedin_banner_image_1.png" style={{ width: 100 }} />} value="home" />
        </Tooltip>
        <Tab label={<p>{t("component.Header.introduction")}</p>} value="introduction" />
        <Tab
          label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p style={{ marginRight: '4px' }}>{t("component.Header.member.member")}</p>
              <Icon icon="raphael:arrowdown" />
            </div>
          }
          value="member"
          aria-controls={memberAnchorEl ? "members-menu" : undefined}
          aria-haspopup="true"
          onClick={handleMemberMenuClick}
        />
        <Menu
          id="members-menu"
          anchorEl={memberAnchorEl}
          keepMounted
          open={Boolean(memberAnchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleMemberItemClick("")}><p style={{ fontSize: "14px" }}>{t("component.Header.member.professor")}</p></MenuItem>
          <MenuItem onClick={() => handleMemberItemClick("assistant")}><p style={{ fontSize: "14px" }}>{t("component.Header.member.assistant")}</p></MenuItem>
          <MenuItem onClick={() => handleMemberItemClick("postDoctor")}><p style={{ fontSize: "14px" }}>{t("component.Header.member.postdoc")}</p></MenuItem>
          <MenuItem onClick={() => handleMemberItemClick("phd")}><p style={{ fontSize: "14px" }}>{t("component.Header.member.phd")}</p></MenuItem>
          <MenuItem onClick={() => handleMemberItemClick("master")}><p style={{ fontSize: "14px" }}>{t("component.Header.member.master")}</p></MenuItem>
          <MenuItem onClick={() => handleMemberItemClick("alumni")}><p style={{ fontSize: "14px" }}>{t("component.Header.member.alumni")}</p></MenuItem>
          <MenuItem onClick={() => handleMemberItemClick("relatedresearcher")}><p style={{ fontSize: "14px" }}>{t("component.Header.member.related")}</p></MenuItem>
        </Menu>
        <Tab label={<p>{t("component.Header.researchDirection")}</p>} value="research" />
        <Tab label={<p>{t("component.Header.publication")}</p>} value="publication" />
        <Tab label={<p>{t("component.Header.project")}</p>} value="project" />
      </Tabs>
      <Button 
        variant="text"
        onClick={() => handleLanguageChange()}
        style={{ position: "absolute", right: "10px" }}
      >
        <p>{languageText}</p>
      </Button>
    </Box>
  )
}

export default Header;
