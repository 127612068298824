import { useRoutes } from 'react-router-dom';
import Welcome from './pages/Welcome';
import Introduction from './pages/Introduction';
import Member from './pages/member/Member';
import Assistant from './pages/member/Assistant';
import Project from './pages/Project';
import Publication from './pages/Publication';
import PostDoc from './pages/member/PostDoc';
import PhD from './pages/member/PhD';
import Master from './pages/member/Master';
import Relatedresercher from './pages/member/Related';
import Alumni from './pages/member/Alumni';
import Research from './pages/Research';
//import { CUMTEL, Cosci, Gamebase, Storytelling } from './pages/Research';

function Element() {
  const element = useRoutes([
    {
      path: '/',
      element: <Welcome />,
    },
    {
      path: '/about',
      element: <Introduction />,
    },
    {
      path: '/members/relatedresearcher',
      element: <Relatedresercher />,
    },
    {
      path: '/members/alumni',
      element: <Alumni />
    },
    {
      path: '/members/master',
      element: <Master />,
    },
    {
      path: '/members/assistant',
      element: <Assistant />,
    },
    {
      path: '/members/postDoctor',
      element: <PostDoc />,
    },
    {
      path: '/members/phd',
      element: <PhD />,
    },
    {
      path: '/members/',
      element: <Member />,
    },
    /*{
      path: '/research/cumtel',
      element: <CUMTEL />,
    },
    {
      path: '/research/gamebase',
      element: <Gamebase />,
    },
    {
      path: '/research/storytelling',
      element: <Storytelling />,
    },
    {
      path: '/research/sciencesimulation',
      element: <Cosci />,
    },
    {
      path: '/research/',
      element: <Cosci />,
    },*/
    {
      path: '/research/',
      element: <Research />,
    },
    {
      path: '/project',
      element: <Project />,
    },
    {
      path: '/publication',
      element: <Publication />,
    },
  ]);

  return element;
}

export default Element;
