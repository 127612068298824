import React from 'react';
import Prof from './Prof';
import Layout from '../../component/Layout';

const Member = () => {

  return (
    <Layout>
      <Prof />
    </Layout>
  );
}

export default Member;
