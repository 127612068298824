export const members_111 = [
    {
        imgURL: "/images/members/111/member_ms_11101.png",
        name: {
            chinese: "戴士凱",
            english: "SHIH KAI TAI"
        },
        email: "derrickauvb@gmail.com",
        spec: "Python"
    },
    {
        imgURL: "/images/members/111/member_ms_11102.png",
        name: {
            chinese: "林亞岑",
            english: "YA TSEN LIN"
        },
        email: "tina30124@gmail.com",
        spec: "C/C++ , Java, Python"
    },
    {
        imgURL: "/images/members/111/member_ms_11103.png",
        name: {
            chinese: "許志仲",
            english: "CHIH CHUNG HSU"
        },
        email: "johnny65423@gmail.com",
        spec: "C/C++, Python"
    },
    {
        imgURL: "/images/members/111/member_ms_11104.png",
        name: {
            chinese: "朱翊瑄",
            english: "YI SYUAN CHU"
        },
        email: "sandy2127521@gmail.com",
        spec: "C, Java, Python"
    },
    {
        imgURL: "/images/members/111/member_ms_11105.png",
        name: {
            chinese: "黃煒翔",
            english: "WEI XIANG HUANG"
        },
        email: "z1127000@gmail.com",
        spec: "C/C++, Python"
    }
]
