import React from "react";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";

const ProfCard = ({ imgURL, name, title, children }) => {
    
    return (
        <Card sx={{ maxWidth: 345, backgroundColor: "rgb(250, 250, 244)" }}>
            <CardMedia
                sx={{ height: 300 }}
                image={imgURL}
                name={name}
                title={title}
            />
            <CardContent>
                <Typography component="div">
                    <h5>{name} {title !== "" ? (<span style={{ fontSize: "16px", fontStyle: "GenSanRounded" }}>{title}</span>) : (<></>)}</h5>
                </Typography>
                {children}
            </CardContent>
        </Card>
    );
};

export default ProfCard;