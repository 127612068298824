export const members_113 = [
  {
    imgURL: "/images/members/113/member_ms_11301.jpg",
    name: {
      chinese: "林佳何",
      english: "CHIA HO LIN",
    },
    email: "linchiah01@gmail.com",
    spec: "C++, Python",
  },
  {
    imgURL: "/images/members/113/member_ms_11302.jpg",
    name: {
      chinese: "徐得銘",
      english: "DE MING HSU",
    },
    email: "b0986110133@gmail.com",
    spec: "C++, C#, Python",
  },
  {
    imgURL: "/images/members/113/member_ms_11303.jpg",
    name: {
      chinese: "劉冠麟",
      english: "GUAN LIN LIU",
    },
    email: "galenliu910322@gmail.com",
    spec: "C++, Python",
  },
  {
    imgURL: "/images/members/113/member_ms_11304.jpg",
    name: {
      chinese: "翁沁翎",
      english: "CHIN LING WENG",
    },
    email: "s09350326@gmail.com",
    spec: "C++, Python",
  },
  {
    imgURL: "/images/members/113/member_ms_11305.jpg",
    name: {
      chinese: "蔡佩蓉",
      english: "PEI JUNG TSAI",
    },
    email: "sandy9636@gmail.com",
    spec: "C, Python",
  },
  {
    imgURL: "/images/members/113/member_ms_11306.jpg",
    name: {
      chinese: "陳奕婷",
      english: "YI TING CHEN",
    },
    email: "x.christinechen.x@gmail.com",
    spec: "React.js, Vue.js, Python",
  },
];
