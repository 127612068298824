export const monograph = {
    "sections": [
        {
            "publications": [
                {
                    "authors": ["Wu, L. Y.", "Liu, C. C."],
                    "title": "Supporting seamless learning across individual, collaborative, and community-based learning in one-to-one learning age.",
                    "ref": "In Seamless Learning in the Age of Mobile Connectivity (pp. 453-470). Springer, Singapore. (ISBN: 978-981-287-113-8).",
                    "year": 2022
                },
                {
                    "authors": ["李致中", "鍾陳威", "劉晨鐘"],
                    "title": "第四章: 合作學習教室設計：使用一人一機支援面對面的合作學習。",
                    "ref": "未來教室、行動與無所不在學習（ISBN：978-986-266-098-0）（2(4),53-70）。臺灣：高等教育文化事業有限公司。",
                    "year": "2014年10月"
                },
                {
                    "authors": ["Liu, C. C"],
                    "title": "Toward Creator-Based Learning: Designs That Help Student Makers Learn.",
                    "ref": "Second Handbook of Information Technology in Primary and Secondary Education, 921. Springer. 2018. (ISBN: 978-3-319-71053-2).",
                    "year": 2018
                },
            ]
        }
    ]
}