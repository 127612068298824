import React from 'react';
import { HashRouter as Router } from 'react-router-dom';

/*pages*/
import Element from './Element';

const App = () => {
  return (
    <Router>
      <Element />
    </Router>
  );
};

export default App;
