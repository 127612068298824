import { useTranslation } from "react-i18next";

import { Grid, Box } from '@mui/material';
import '../css/style.css';
import Header from "../component/Header";
import Footer from "../component/Footer";

const Introduction = () => {
  const { t } = useTranslation();

  return (
    <Box minHeight="100vh" position="relative">
      <Header />
      <Grid container spacing={2} padding={1} >
        {/* 左邊放置圖片 */}
        <Grid item xs={12} sm={3}>
          <img src="/images/introduction_image.jpg" alt="Introduction" style={{ width: '100%', height: 'auto' }} />
        </Grid>

        {/* 右邊放置標題、內文 */}
        <Grid item xs={12} sm={8}>
          <Box p={2} sx={{ maxWidth: '100%' }}>
            <h5>{t("page.Introduction.title")}</h5>
            <p>{t("page.Introduction.paragraph1")}</p>
            <p>{t("page.Introduction.paragraph2")}</p>
            <p>{t("page.Introduction.paragraph3")}</p>
          </Box>
        </Grid>
      </Grid>
      <Footer />
    </Box>
  );
};

export default Introduction;
