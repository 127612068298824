export const members_108 = [
    {
        imgURL: "/images/members/108/member_ms_10704.png",
        name: {
            chinese: "男蘇亞",
            english: "Surya Kumara"
        },
        email: "suryakumara33@gmail.com",
        spec: "Arduino, C, JavaScript"
    },
    {
        imgURL: "/images/members/108/member_ms_10705.png",
        name: {
            chinese: "賴柏翰",
            english: "BO HAN LAI"
        },
        email: "andyzzz12345@gmail.com",
        spec: "C, C++, Python"
    },
    {
        imgURL: "/images/members/108/member_ms_10801.png",
        name: {
            chinese: "廖墨剛",
            english: "Mo Gang Liao"
        },
        email: "bowman963852@gmail.com",
        spec: "C#, C, Java, HTML"
    },
    {
        imgURL: "/images/members/108/member_ms_10802.jpg",
        name: {
            chinese: "馮永婷",
            english: "Yong Ting Feng"
        },
        email: "japan851220@gmail.com",
        spec: "C#, C, Java, Android"
    },
    {
        imgURL: "/images/members/108/member_ms_10803.jpg",
        name: {
            chinese: "周彥丞",
            english: "YAN CHENG ZHOU"
        },
        email: "aa10427077@gmail.com",
        spec: "C#, C, Java, HTML"
    },
]