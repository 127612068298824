import React from 'react';
import { Grid, Box, IconButton, useMediaQuery } from '@mui/material';
import { Icon } from '@iconify/react';

function ResearchPage({ data }) {
    const isSmallScreen = useMediaQuery('(max-width:1000px)');
    const handleRef = () => {
        if (data.ref && data.ref.link) {
            window.open(data.ref.link, '_blank');
        }
    }

    return (
        <Grid container padding={2}>
            <Grid item sm={12}>
                {data.title && <h5>{data.title}</h5>}
                {data.ref && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {data.ref.link && (
                            <IconButton onClick={handleRef}>
                                <Icon icon="mdi:web" />
                            </IconButton>
                        )}
                        {data.ref.title && <p>{data.ref.title}</p>}
                    </Box>
                )}
            </Grid>
            <Grid item xs={12} sm={isSmallScreen ? 12 : 8} sx={{ marginRight: 10 }}>
                <Box p={2} sx={{ maxWidth: '100%' }}>
                    {Array.isArray(data.description) ? (
                        data.description.map((item, index) => (
                            <div key={index}>
                                <h6>{item.title}</h6>
                                <p>{item.p}</p>
                                {item.images && (
                                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                        {item.images.map((imageGroup, groupIndex) => (
                                            <div key={groupIndex}>
                                                {Array.isArray(imageGroup.imgURL) && (
                                                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            {imageGroup.imgURL.map((url, idx) => (
                                                                <div key={idx}>
                                                                    <img
                                                                        src={url}
                                                                        alt={`${imageGroup.desc} ${idx + 1}`}
                                                                        style={{ width: 'auto', height: '250px' }}
                                                                    />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </Box>
                                                )}
                                                {imageGroup.desc && (
                                                    <p style={{ textAlign: 'center', marginTop: '10px' }}>{imageGroup.desc}</p>
                                                )}
                                            </div>
                                        ))}
                                    </Box>
                                )}
                            </div>
                        ))
                    ) : (
                        <p>{data.description}</p>
                    )}
                    {data.logo && (
                        <Box marginTop={8}> {/* 添加間隙的 Box 元素 */}
                            <img
                                src={data.logo}
                                alt="Cosci"
                                style={{ width: '100%', height: 'auto' }}
                            />
                        </Box>
                    )}
                </Box>
            </Grid>
            {data.images && (
                <Grid item xs={12} sm={isSmallScreen ? 12 : 3}>
                    <div style={{ display: 'flex', flexDirection: isSmallScreen ? 'row' : 'column', justifyContent: 'center', alignItems: 'center', gap: '20px' }}>
                        {data.images.map((image, index) => (
                            <div key={index}>
                                {Array.isArray(image.imgURL) ? (
                                    image.imgURL.map((url, idx) => (
                                        <img
                                            key={idx}
                                            src={url}
                                            alt={`${image.desc} ${idx + 1}`}
                                            style={{ width: '100%', height: 'auto' }}
                                        />
                                    ))
                                ) : (
                                    <img
                                        src={image.imgURL}
                                        alt={image.desc}
                                        style={{ width: '100%', height: 'auto' }}
                                    />
                                )}
                                {image.desc && <p style={{ textAlign: 'center' }}>{image.desc}</p>}
                            </div>
                        ))}
                    </div>
                </Grid>
            )}
        </Grid>
    );
}

export default ResearchPage;
