import React from 'react';
import { Box, IconButton } from '@mui/material';
import { Icon } from '@iconify/react';

function copyText(text) {
    // Attempt to use the Clipboard API to copy the text
    const copyNotify = "Copied " + text
    navigator.clipboard.writeText(text)
        .then(() => {
            // Successfully copied to clipboard
            const notification = document.createElement('div');
            notification.textContent = copyNotify;
            notification.style.position = 'fixed';
            notification.style.bottom = '20px';
            notification.style.right = '20px';
            notification.style.backgroundColor = 'white';
            notification.style.color = 'rgb(44, 64, 87)';
            notification.style.fontFamily = 'GenSanRounded'
            notification.style.padding = '10px';
            notification.style.borderRadius = '5px';
            notification.style.border = '1px solid #ccc';

            // Append the notification to the document
            document.body.appendChild(notification);

            // Automatically dismiss the notification after a few seconds (e.g., 2 seconds)
            setTimeout(() => {
                document.body.removeChild(notification);
            }, 2000);
        })
        .catch(err => {
            // Handle clipboard write failure (e.g., permissions denied)
            console.error('Failed to copy: ', err);
        });
}

const InfoBox = ({ icon, title, text }) => {

    const handleCopy = () => {
        // 如果text是数组，则将其转换为字符串，否则保持text不变
        console.log(text)
        const copiedText = Array.isArray(text) ? text.join('\n') : text;
        copyText(copiedText);
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: 'fit-content' }}>
                {icon && (
                    <IconButton onClick={handleCopy}>
                        <Icon icon={icon} />
                    </IconButton>
                )}
                {title && (
                    <p style={{ fontSize: '14px', margin: '0', marginLeft: '2.5px', width: '40px' }}>{title}</p>
                )}
            </Box>
            {Array.isArray(text) ? (
                <p style={{ fontSize: '14px', margin: '0', marginLeft: '5px' }}>
                    {text.map((item, index) => (
                        <React.Fragment key={index}>
                            {item}
                            {index !== text.length - 1 && <br />} {/* Add a <br /> except for the last item */}
                        </React.Fragment>
                    ))}
                </p>
            ) : (
                <p style={{ fontSize: '14px', margin: '0', marginLeft: '35px' }}>{text}</p>
            )}
        </Box>
    );
};

export default InfoBox;
