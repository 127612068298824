import React from "react";
import { useTranslation } from "react-i18next";

import Layout from "../../component/Layout";
import MasterGrid from "../../component/MasterGrid";
import { members_112 } from "./info/112";
import { members_113 } from "./info/113";
import { Box } from "@mui/material";

const Master = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <Box padding={2}>
                <MasterGrid title={t("page.member.Master.title.1")} members={members_112} />
                <MasterGrid title={t("page.member.Master.title.0")} members={members_113} />
            </Box>
        </Layout>
    )
}

export default Master