export const members_109 = [
    {
        imgURL: "/images/members/109/member_ms_10904.png",
        name: {
            chinese: "邱貞瑋",
            english: "ZHEN WEI QIU"
        },
        email: "a3kk0025@gmail.com",
        spec: "C, C++, Python, Java"
    },
    {
        imgURL: "/images/members/109/member_ms_10901.jpg",
        name: {
            chinese: "董彥甫",
            english: "YAN FU DONG"
        },
        email: "eric78829@gmail.com",
        spec: "Python, Javascript, HTML"
    },
]