export const members_106 = [
    {
        imgURL: "/images/members/106/Members_PD_10601.jpg",
        name: {
            chinese: "溫采婷",
            english: "Cai Ting Wen"
        },
        email: "leafhere@gmail.com",
        spec: "C++"
    },
    {
        imgURL: "/images/members/106/member_ms_10601.jpg",
        name: {
            chinese: "馬森豪",
            english: "Sen Hao Ma"
        },
        email: "peaceway10608@gmail.com",
        spec: "C, Java, Android"
    },
    {
        imgURL: "/images/members/106/member_ms_10602.jpg",
        name: {
            chinese: "何永育",
            english: "Yung Yu Ho"
        },
        email: "rtfg840429@gmail.com",
        spec: "C, C#, Flash"
    },
    {
        imgURL: "/images/members/106/member_ms_10603.jpg",
        name: {
            chinese: "謝翌甄",
            english: "I Chen Hsieh"
        },
        email: "lisa37262003@gmail.com",
        spec: "Android, PHP"
    },
    {
        imgURL: "/images/members/106/member_ms_10604.jpg",
        name: {
            chinese: "奧薩雅",
            english: "AuLia Syarif Aziz"
        },
        email: "as.sektim@gmail.com",
        spec: "Javascript, PHP"
    },
]