export const members_110 = [
    {
        imgURL: "/images/members/110/member_ms_11001.png",
        name: {
            chinese: "吳一平",
            english: "YI PING WU"
        },
        email: "water0321ayer@gmail.com",
        spec: "Java, JavaScript, Python, C#"
    },
    {
        imgURL: "/images/members/110/member_ms_11002.png",
        name: {
            chinese: "陳宛君",
            english: "WAN JUN CHEN"
        },
        email: "joychen00474@gmail.com",
        spec: "C, C++, C#, Python"
    },
    {
        imgURL: "/images/members/110/member_ms_11003.png",
        name: {
            chinese: "陳俊豪",
            english: "JUN HAO CHEN"
        },
        email: "as881114@gmail.com",
        spec: "C#, Java"
    },
    {
        imgURL: "/images/members/110/member_ms_11004.png",
        name: {
            chinese: "曾鉉閎",
            english: "HSUAN HUNG TSENG"
        },
        email: "meiroan77@gmail.com",
        spec: "C#, C++"
    },
    {
        imgURL: "/images/members/110/member_ms_11005.png",
        name: {
            chinese: "林彥宇",
            english: "YEN YU LIN"
        },
        email: "linliuacg@gmail.com",
        spec: "C/C++, C#, JavaScrpit, Python"
    },
]