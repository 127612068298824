import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import Layout from "../../component/Layout";
import MasterGrid from "../../component/MasterGrid";
import { Box } from '@mui/material';

/* import members' info */
import { members_111 } from "./info/alumni/111";
import { members_110 } from "./info/alumni/110";
import { members_109 } from "./info/alumni/109";
import { members_108 } from "./info/alumni/108";
import { members_107 } from "./info/alumni/107";
import { members_106 } from "./info/alumni/106";
import { members_105 } from "./info/alumni/105";
import { members_104 } from "./info/alumni/104";
import { members_103 } from "./info/alumni/103";
import { members_102 } from "./info/alumni/102";
import { members_101 } from "./info/alumni/101";
import { members_100 } from "./info/alumni/100";
import { members_99 } from "./info/alumni/99";
import { members_98 } from "./info/alumni/98";
import { members_97 } from "./info/alumni/97";
import { members_96 } from "./info/alumni/96";
import { members_95 } from "./info/alumni/95";
import { members_94 } from "./info/alumni/94";

const Alumni = () => {
    const { t } = useTranslation();

    const [loadedYears, setLoadedYears] = useState([111, 110, 109]); // Initially load the first year
    const [allMembers, setAllMembers] = useState({
      111: members_111,
      110: members_110,
      109: members_109,
      108: members_108,
      107: members_107,
      106: members_106,
      105: members_105,
      104: members_104,
      103: members_103,
      102: members_102,
      101: members_101,
      100: members_100,
      99: members_99,
      98: members_98,
      97: members_97,
      96: members_96,
      95: members_95,
      94: members_94,
    });

    const lastYearRef = useRef(null);

    const loadMoreData = () => {
        const nextYear = loadedYears[loadedYears.length - 1] - 1;
        if (nextYear >= 94) {
            setLoadedYears([...loadedYears, nextYear]);
        }
    };

    useEffect(() => {
        const handleIntersection = (entries) => {
            const entry = entries[0];
            if (entry.isIntersecting) {
                loadMoreData();
            }
        };

        const observer = new IntersectionObserver(handleIntersection, {
            root: null,
            rootMargin: "0px",
            threshold: 0.1, // Adjust as needed
        });

        if (lastYearRef.current) {
            observer.observe(lastYearRef.current);
        }

        return () => {
            if (lastYearRef.current) {
                observer.unobserve(lastYearRef.current);
            }
        };
    }, [loadedYears]);

    return (
        <Layout>
            {loadedYears.map((year, index) => (
                <Box key={year} padding={2} ref={index === loadedYears.length - 1 ? lastYearRef : null}>
                    <MasterGrid title={year + " " + t("page.member.Alumni.title")} members={allMembers[year]} />
                </Box>
            ))}
        </Layout>
    );
}

export default Alumni;
