import { useTranslation } from "react-i18next";

export const AIapplication = () => {
    const { t } = useTranslation();

    return{
        title: t("page.research.ai_application.title"),
        description: [
            {
                p: t("page.research.ai_application.description"),
            },
            {
                images: [
                    {
                        desc: t("page.research.ai_application.images.0.desc"),
                        imgURL:["/images/research/ai_application/ai_robot.png"]
                    },
                    {
                        desc: t("page.research.ai_application.images.1.desc"),
                        imgURL:["/images/research/ai_application/training_robot.png"]
                    },
                ]
            },
            {
                images: [
                    {
                        desc: t("page.research.ai_application.images.2.desc"),
                        imgURL:["/images/research/ai_application/interface.png"]
                    },
                    {
                        desc: t("page.research.ai_application.images.3.desc"),
                        imgURL:["/images/research/ai_application/conversation_record.png"]
                    },
                ]
            }
        ],
    }
}