import * as React from 'react'
import { useTranslation } from "react-i18next";

import { Grid } from '@mui/material';
import ProfCard from '../../component/ProfCard';
import InfoBox from '../../component/InfoBox';

import { ProfessorInfo } from './info/professor';

const Prof = () => {
    const { t } = useTranslation();
    const info = ProfessorInfo()

    return (
        <Grid container spacing={2} padding={2} alignItems="flex-start">
            <Grid item xs={12} sm={4}>
                <ProfCard
                    imgURL={info.imgURL}
                    name={info.name}
                    title={info.title}
                    minHeight={700}
                >
                    <InfoBox icon="material-symbols:work" text={info.department} />
                    <InfoBox icon="mingcute:phone-fill" text={info.officePhone} />
                    <InfoBox icon="mdi:office-building-location" text={info.officeLocation} />
                    <InfoBox icon="ic:baseline-email" text={info.email} />
                </ProfCard>
            </Grid>
            <Grid item xs={12} sm={6} textAlign={'left'}>
                <h5>{t("page.member.Prof.currentPosition")}</h5>
                <ul>
                    {info.currentPosition.map((item, index) => (
                        <li key={index}>
                            <p style={{ fontSize: '16px' }}>{item}</p>
                        </li>
                    ))}
                </ul>
                <h5>{t("page.member.Prof.experience")}</h5>
                <ul>
                    {info.experience.map((item, index) => (
                        <li key={index}>
                            <p style={{ fontSize: '16px' }}>{item}</p>
                        </li>
                    ))}
                </ul>
                <h5>{t("page.member.Prof.educationalBackground")}</h5>
                <ul>
                    {info.education.map((item, index) => (
                        <li key={index}>
                            <p style={{ fontSize: '16px' }}>{item}</p>
                        </li>
                    ))}
                </ul>
                <h5>{t("page.member.Prof.academicExpertise")}</h5>
                <ul>
                    {info.academicSpecialties.map((item, index) => (
                        <li key={index}>
                            <p style={{ fontSize: '16px' }}>{item}</p>
                        </li>
                    ))}
                </ul>
                <h5>{t("page.member.Prof.awardWinningGlory")}</h5>
                <ul>
                    {info.awards.map((item, index) => (
                        <li key={index}>
                            <p style={{ fontSize: '16px' }}>{item}</p>
                        </li>
                    ))}
                </ul>
                <h5>{t("page.member.Prof.academicServices")}</h5>
                <ul>
                    {info.academicService.map((service, index) => (
                        <li key={index}>
                            <p style={{ fontSize: '16px', fontWeight: service.to === '.' ? 'bold' : 'normal' }}>
                                {service.title}, {service.from} {service.to ? `～ ${service.to}` : ''}
                            </p>
                        </li>
                    ))}
                </ul>
            </Grid>
        </Grid>
    )
}

export default Prof