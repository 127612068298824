import React, { useEffect, useRef, useState } from 'react';

import '../css/style.css'

import mojs from "@mojs/core";
import Cover from '../component/cover.js';

const getWindowSize = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
};

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

const Welcome = () => {
  const animationRef = useRef();
  const windowSize = useWindowSize();
  const [enableScroll, setScroll] = useState(true);

  const COLORS = {
    white: 'rgb(250, 250, 244)',
    blue: 'rgb(44, 64, 87)'
  };

  const _calcScale = (radius) => {
    return 1.4 * (Math.max(windowSize.width, windowSize.height) / radius / 2);
  };

  let circleSize = 500,
    scale = _calcScale(circleSize),
    opts = {
      el: animationRef.current,
      radius: circleSize,
      fill: COLORS.blue,
      scale: { 0.05: 0.2 },
      duration: 465,
      easing: 'cubic.out',
      isShowEnd: false,
      isForce3d: true,
    };

  useEffect(() => {

    setScroll(false);

    const circle1 = new mojs.Shape(opts)
      .then({
        easing: 'cubic.inout',
        scale: 0.125,
        duration: 350,
      })
      .then({
        easing: 'cubic.inout',
        scale: scale,
        duration: 465,
      });

    const circle2 = new mojs.Shape({
      ...opts,
      fill: COLORS.white,
      scale: { 0: 0.1125 },
      duration: 407,
      delay: 580,
    }).then({
      easing: 'cubic.inout',
      scale: scale,
      duration: 350,
    });

    const circle3 = new mojs.Shape({
      ...opts,
      scale: { 0: scale },
      duration: 580,
      delay: 1160,
      isShowStart: true,
    }).then({
      easing: 'cubic.inout',
      opacity: { 1: 0 },
      duration: 350,
    });

    const smallCircle = new mojs.Shape({
      el: animationRef.current,
      radius: { 5: 25 },
      fill: 'none',
      stroke: COLORS.blue,
      strokeWidth: { 20: 0 },
      isShowEnd: false,
      delay: 700,
      duration: 290,
    });

    const lineOpts = {
      el: animationRef.current,
      shape: 'line',
      x: -180,
      radius: 50,
      radiusY: 0,
      stroke: COLORS.blue,
      strokeWidth: { 15: 0 },
      duration: 580,
      isShowEnd: false,
      strokeDasharray: '100% 100%',
      strokeDashoffset: { '-100%': '100%' },
      easing: 'cubic.out',
    };
    const line1 = new mojs.Shape(lineOpts);

    const line2 = new mojs.Shape({
      ...lineOpts,
      angle: 180,
      x: -lineOpts.x,
    });

    const text = new mojs.Html({
      el: animationRef.current,
      scale: { 0: 1 },
      opacity: { 0: 1 },
      duration: 400,
      delay: 1160,
    });

    const timeline = new mojs.Timeline();
    timeline
      .add(
        circle1,
        circle2,
        circle3,
        smallCircle,
        line1,
        line2,
        text,
      );

    timeline.play();
  }, []);

  useEffect(() => {
    if (!enableScroll) {
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto'; // Restore overflow on component unmount
    };
  }, [enableScroll]);


  return (
      <div
        ref={animationRef}
      >
        <Cover />
      </div>
  );
};

export default Welcome;
