import React from "react";
import { useTranslation } from "react-i18next";

import Layout from "../../component/Layout";
import MemberCard from "../../component/MemberCard";
import { Grid } from "@mui/material";

import { AssistantData } from "./info/assistant";

const Assistant = () => {
    const { i18n } = useTranslation();
    
    const assistant = AssistantData();
    // 找出 assistant 中資料最多的一筆，計算最大高度
    const maxContentLength = Math.max(...assistant.map((assistant) => {
        return (
            (assistant.name?.length || 0) +
            (assistant.email?.length || 0) +
            (assistant.workContent?.length || 0) +
            (assistant.spec?.length || 0) +
            (assistant.officePhone?.length || 0)
        );
    }));

    // 設定最小高度為所有卡片資料最多的高度
    const minHeight = i18n.language === "zh" ? maxContentLength * 8.5 : maxContentLength * 5; // 假設每個字元平均高度為 3

    return (
        <Layout>
            <Grid container spacing={3} padding={2}>
                {assistant.map((assistant, index) => (
                    <Grid item key={index}>
                        <MemberCard
                            minHeight={`${minHeight}px`}
                            imgURL={assistant.imgURL}
                            name={assistant.name}
                            email={assistant.email}
                            officePhone={assistant.officePhone ? assistant.officePhone : null}
                            workContent={assistant.workContent}
                            spec={assistant.spec}
                        >
                        </MemberCard>
                    </Grid>
                ))}
            </Grid>
        </Layout>
    )
}

export default Assistant;
