export const members_94 = [
    {
        imgURL: "/images/members/94/Members_PD_r4_c5.png",
        name: {
            chinese: "鍾陳威",
            english: "Jong Chen-Wei"
        },
        email: "jerryjong@gmail.com",
        spec: "Linux.Windows 伺服器架設管理 , SQL , PHP, ASP, .NET, C/C++, C#"
    },
    {
        imgURL: "/images/members/94/Members_94_r5_c3.png",
        name: {
            chinese: "郭旺鑫",
            english: "Andy"
        },
        email: "945204002@cc.ncu.edu.tw",
        spec: "Java Programming, Multimedia, Web Programming"
    },
    {
        imgURL: "/images/members/94/Members_94_r6_c1.png",
        name: {
            chinese: "沈伯翰",
            english: "Stalu Shen"
        },
        email: "stalu@tw-mcse.net",
        spec: "Multimedia. Web Design"
    },
    {
        imgURL: "/images/members/94/Members_94_r7_c3.png",
        name: {
            chinese: "王惠民",
            english: "Hui-Min Wang"
        },
        email: "s946028@mail.yzu.edu.tw",
        spec: "C/C++"
    },
    {
        imgURL: "/images/members/94/Members_94_r8_c1.png",
        name: {
            chinese: "王碧輝",
            english: "Bing-Huei Wang"
        },
        email: "s946026@mail.yzu.edu.tw",
        spec: "JAVA"
    },
    {
        imgURL: "/images/members/94/Members_94_r9_c3.png",
        name: {
            chinese: "賀廣文",
            english: "Kuang-Wen Ho"
        },
        email: "s946060@mail.yzu.edu.tw",
        spec: "C/C++"
    }
]