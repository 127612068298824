export const members_105 = [
    {
        imgURL: "/images/members/105/member_ms_10502.jpg",
        name: {
            chinese: "彭郁翔",
            english: "Yu Siang Peng"
        },
        email: "davidpeng0216@gmail.com",
        spec: "C/C++"
    },
    {
        imgURL: "/images/members/105/member_ms_10501.jpg",
        name: {
            chinese: "劉貞吟",
            english: "Chen Yin Liou"
        },
        email: "broccoli247596@gmail.com",
        spec: "C, C++, Android, Java"
    }
]