import { useTranslation } from "react-i18next";

export const RelatedResearcher = () => {
    const { t } = useTranslation();
    
    return [
        {
            imgURL: "/images/members/relatedresearcher/member_ProDoc01.JPG",
            name: {
                chinese: "吳昱鋒",
                english: "Leon Yufeng Wu"
            },
            title: "博士",
            workIn: t("page.member.info.relatedresearcher.workIn.wu", { returnObjects: true }),
        },
        {
            imgURL: "/images/members/relatedresearcher/member_ProDoc02.png",
            name: {
                chinese: "林佳慶",
                english: "Chia-Ching Lin"
            },
            title: "博士",
            workIn: t("page.member.info.relatedresearcher.workIn.lin", { returnObjects: true }),
        },
        {
            imgURL: "/images/members/relatedresearcher/team_changcj.jpg",
            name: {
                chinese: "張家榮",
                english: "Baileys Chia-Jung, Chung"
            },
            workIn: t("page.member.info.relatedresearcher.workIn.chung", { returnObjects: true }),
        },
        {
            imgURL: "/images/members/relatedresearcher/team_ChiaHui.jpg",
            name: {
                chinese: "鄭嘉惠",
                english: "Chia Hui Cheng"
            },
            workIn: t("page.member.info.relatedresearcher.workIn.cheng", { returnObjects: true }),
        },
        {
          imgURL: "/images/members/phd/Dominique.png",
          name: {
            chinese: "劉國平",
            english: "Dominique",
          },
          // email: "domi.liu330@gmail.com",
          // spec: "collaborative storytelling, children's English learning",
          workIn: [""],
        },
        {
          imgURL: "/images/members/postDoc/team_minghua.jpg",
          name: {
            chinese: "張銘華",
            english: "Ming Hua Chang",
          },
          // email: "mhchang020@gmail.com",
          // spec: "物理模擬程式設計",
          workIn: [""],
        },
        {
          imgURL: "/images/members/relatedresearcher/team_CaiTing.jpg",
          name: {
            chinese: "溫采婷",
            english: "Cai Ting Wen",
          },
          workIn: t("page.member.info.relatedresearcher.workIn.wen", { returnObjects: true }),
        },
    ]
}