import { useTranslation } from "react-i18next";

export const Postdoctor = () => {
    const { t } = useTranslation();

    return [
        {
            imgURL: "/images/members/postDoc/ying_siou.jpg",
            name: {
                chinese: "陳盈秀",
                english: "Ying Siou Chen"
            },
            email: "amy780617@gmail.com",
            spec: t("page.member.info.postdoctor.spec.1")
        }
    ]
}