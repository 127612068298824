import * as React from 'react';
import { Typography, Card, CardMedia, CardContent, Box } from '@mui/material';
import InfoBox from './InfoBox';
import { useTranslation } from "react-i18next";

const MemberCard = (props) => {
    const { t } = useTranslation();
    const { minHeight, imgURL, workIn, title, name, email, officePhone, edu, spec, workContent, children, maxWidth } = props;

    return (
        <Box
            sx={{
                backgroundColor: '#C1f1f1',
                borderRadius: 2,
                boxShadow: 2,
            }}
        >

            <Card sx={{ minHeight: (minHeight ? minHeight : "390px"), minWidth: "260px", maxWidth: (maxWidth ? maxWidth : "300px") }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    {(imgURL) && <CardMedia
                        component="img"
                        image={imgURL}
                        alt="Not Found"
                        sx={{ width: '100%', display: 'block', height: '200px', objectFit: 'contain', objectPosition: '50% 0%' }}

                    />}
                </Box>
                <CardContent sx={{ fontSize: "14px" }}>
                    <Typography sx={{ fontFamily: 'GenSanRounded' }} component="div">
                        {name && (
                            <div>
                                <p style={{ fontSize: "16px", marginBottom: 0 }}>
                                    <span style={{ marginRight: '5px' }}>{name.chinese}</span>
                                    {title && (
                                        <span>{title}</span>
                                    )}
                                </p>
                                <p style={{ fontSize: "16px", marginBottom: '5px' }}>
                                    <span>{name.english}</span>
                                </p>
                            </div>
                        )}
                    </Typography>
                    <Typography>
                        {(email) && <InfoBox icon="ic:baseline-email" text={email} />}
                        {(officePhone) && <InfoBox icon="mingcute:phone-fill" text={officePhone} />}
                        {(workIn) && <InfoBox icon="material-symbols:work" text={workIn} />}
                        {(edu) && <InfoBox text={edu} />}
                        {(spec) && <InfoBox title={t("component.MemberCard.spec")} text={spec} />}
                        {(workContent) && <InfoBox title={t("component.MemberCard.workContent")} text={workContent} />}
                        {children}
                    </Typography>
                </CardContent>
            </Card>
        </Box >
    )
}

export default MemberCard;