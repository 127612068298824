export const members_107 = [
    {
        imgURL: "/images/members/107/member_ms_10605.jpg",
        name: {
            chinese: "夢荷",
            english: "Munkhgerel Galbadrakh"
        },
        email: "peaceway10608@gmail.com",
        spec: "Objective-C"
    },
    {
        imgURL: "/images/members/107/member_ms_10701.jpg",
        name: {
            chinese: "李芸",
            english: "Yun Lee"
        },
        email: "rtfg840429@gmail.com",
        spec: "C++, HTML 5"
    },
    {
        imgURL: "/images/members/107/member_ms_10702.jpg",
        name: {
            chinese: "王楠鈞",
            english: "Nan Chun Wang"
        },
        email: "lisa37262003@gmail.com",
        spec: "C++, JAVA"
    },
    {
        imgURL: "/images/members/107/member_ms_10703.jpg",
        name: {
            chinese: "林尊韋",
            english: "Tsun Wei Lin"
        },
        email: "as.sektim@gmail.com",
        spec: "JAVA"
    },
]