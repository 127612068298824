import React from 'react';
import { Box, IconButton } from '@mui/material';
import { Icon } from '@iconify/react';

const JournalList = ({ data }) => {
  return (
    <Box padding={2}>
      {data.sections.map((section, index) => (
        <div key={index}>
          {section.sectionTitle && <h6>{section.sectionTitle}</h6>}
          <ol>
            {section.publications.map((publication, pubIndex) => (
              <li key={pubIndex} style={{ lineHeight: '24px', paddingLeft: '36px', textIndent: '-25px', fontSize: '20px', marginBottom: '10px' }}>
                {publication.authors.join(', ')} ({publication.year}) {publication.title}{' '}
                <i>{publication.ref}</i>{publication.page ? `, ${publication.page}` : ''}
                {publication.url && (
                  <IconButton href={publication.url} target="_blank" rel="noopener noreferrer" aria-label="Link">
                    <Icon icon="simple-icons:doi" color="#2c4057" />
                  </IconButton>
                )}
                {' '}{publication.note}
              </li>
            ))}
          </ol>
        </div>
      ))}
    </Box>
  );
};

export default JournalList;
