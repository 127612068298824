export const journal = {
    "sections": [
      {
        "sectionTitle": "",
        "publications": [
          {
            "authors": ["Wen, C. T.","Liu, C. C.*","Li, C. Y.","Chang, M. H.", "Chiang, S. H. F."," Lin, H. M."," Hwang F. K. & Biswas, B."],
            "title": "The learning analytics of computational scientific modeling with self-explanation for subgoals and demonstration scaffolding,",
            "ref": " Computers & Education, 215, 105043.",
            "page": "",
            "year": 2024,
            "url": ""
          },
          {
            "authors": ["Liu C. C."," Chiu, C. W."," Chang, C. H. & F. Y. Lo"],
            "title": "Analysis of a chatbot as a dialogic reading facilitator: Its influence on learning interest and learner interactions,",
            "ref": "Educational technology research and development,",
            "page": "1-29",
            "year": 2024,
            "url": ""
          },
          {
            "authors": ["Liu, C. C."," Chen, W. J."," Lo, F. Y.", "Chang, C. H. ", " Lin, H. M. "],
            "title": "Teachable Q&A agent: the effect of chatbot training by students on reading interest and engagement,",
            "ref": "Journal of Educational Computing Research.",
            "page": "",
            "year": "",
            "url": ""
          },
          {
            "authors": ["Chang, H. Y."," Liu, C. C.*"," Wen, C. T."," Chang, M. H."," Fan Chiang, S. H.", "Hwang, F. K."],
            "title": "The effect of the cyclic curricular design of modelling-based instruction with virtual labs.",
            "ref": "International Journal of Science Education,",
            "page": "1-27",
            "year": 2023,
            "url": ""
          },
          {
            "authors": ["Looi C. K."," Wong S. L."," Kong S. C."," Chan T. W."," Shih J. L.", "Chang B. Wu Y. T.", "Liu C. C.", "Yeh C. Y. C.", "Chen Z. H."," Chien T. C.", "Chou C. Y."," Hung H. C."," Cheng H. "," Liao C. C.Y"],
            "title": " Interest-Driven Creator Theory: case study of embodiment in an experimental school in Taiwan.",
            "ref": "Research and Practice in Technology Enhanced Learning, 18",
            "page": "023-023",
            "year": 2023,
            "url": ""
          },
          {
            "authors": ["Liu, C. C.", "Liao, M. G", "Chang, C. H.", " Lin, H. M."],
            "title": " An analysis of children’ interaction with an AI chatbot and its impact on their interest in reading,",
            "ref": "Computers & Education, 104576.",
            "page": "",
            "year": 2022,
            "url": ""
          },
          {
            "authors": ["Liu, C. C.", "Lin, T. W."," Cheng, C. H.", "Wen, C. T."," Chang, M. H.", "Fan Chiang, S. H.", "..."," Hwang, F. K."],
            "title": " The impact of functional interdependencies of computer simulations on collaborative learning: Evidence from multiple sources.",
            "ref": " Journal of Computer Assisted Learning, 38(2)",
            "page": "455-469",
            "year": 2022,
            "url": ""
          },
          {
            "authors": ["Liu, C. C.", "Wen, C. T.", "Chang, H. Y.", "Chang, M. H.", "Lai, P. H.", "Fan Chiang, S. H.","...","Hwang, F. K."],
            "title": " Augmenting the effect of virtual labs with teacher demonstration and student critique instructional designs to scaffold the development of scientific literacy.",
            "ref": " Instructional Science,",
            "page": "1-31",
            "year": 2022,
            "url": ""
          },
          {
            "authors": ["Wang, Y. C.", "Lee, W. Y.", "Liu, C. C.", "Lin, P. C.", "Wen, C. T."],
            "title": "Investigating the Links Between Students’ Learning Engagement and Modeling Competence in Computer-Supported Modeling-Based Activities.",
            "ref": "Journal of Science Education and Technology. 30",
            "page": "751-765",
            "year": 2021,
            "url": ""
          },
          {
            "authors": ["Liu, C. C.", "Hsieh, I, C.", "Wen, C. T.", "Chang, M. H.", "Fan Chiang, S.-H.", "Tsai, M. J.", "Chang, C. J.", "Hwang, F. K."],
            "title": "The affordances and limitations of collaborative science simulations: The analysis from multiple evidences.",
            "ref": "Computers & Education, 104029.",
            "page": "",
            "year": 2021,
            "url": ""
          },
          {
            "authors": ["Wen, C. T.", "Liu, C. C.", "Chang, H. Y.", "Chang, C. J.", "Chang, M. H.", "Chiang, S. H. F.", "...", "Hwang, F. K."],
            "title": "Students’ guided inquiry with simulation and its relation to school science achievement and scientific literacy.",
            "ref": "Computers & Education, 103830.",
            "page": "",
            "year": 2020,
            "url": ""
          },
          {
            "authors": ["Chang, C. J.", "Liu, C. C.*", "Wen, C. T.", "Tseng, L. W.", "Chang, H. Y.", "Chang, M. H.", "...","Yang, C. W. "],
            "title": "The impact of light-weight inquiry with computer simulations on science learning in classrooms.",
            "ref": "Computers & Education, 146, 103770.",
            "note": "(*corresponding author)",
            "page": "",
            "year": 2020,
            "url": ""
          },
          {
            "authors": ["So, H. J.", "Jong, M. S. Y.", "Liu, C. C.*"],
            "title": "Computational Thinking Education in the Asian Pacific Region.",
            "ref": "The Asia-Pacific Education Researcher, 29,",
            "note": "(*corresponding author)",
            "page": "1-8",
            "year": 2020,
            "url": ""
          },
          {
            "authors": ["Liu, C. C.", "Yang, C. Y.", "Chao, P. Y."],
            "title": " A longitudinal analysis of student participation in a digital collaborative storytelling activity.",
            "ref": "Educational Technology Research and Development,",
            "page": "1-23",
            "year": 2019,
            "url": "https://doi.org/10.1007/s11423-019-09666-3"
          },
          {
            "authors": ["Chao, P. Y.", "Lai R.", "Liu C. C.", "Lin, H. M."],
            "title": "Strengthening social networks in online discussion forums to facilitate help seeking for solving problems.",
            "ref": "Educational Technology & Society.",
            "page": "",
            "year": 2019,
            "url": ""
          },
          {
            "authors": ["Liu, K. P.", "Tai, S. J. D.", "Liu, C. C."],
            "title": "Enhancing language learning through creation: the effect of digital storytelling on student learning motivation and performance in a school English course.",
            "ref": "Educational technology research and development, 66(4)",
            "page": "913-935",
            "year": 2018,
            "url": "https://doi.org/10.1007/s11423-018-9592-z"
          },
          {
            "authors": ["Wen, C. T.", "Chang, C. J.", "Chang, M. H.", "Chiang, S. H. F.", "Liu, C. C.*", "Hwang, F. K.", "Tsai, C. C."],
            "title": "The learning analytics of model-based learning facilitated by a problem-solving simulation game.",
            "ref": "Instructional science, 46(6)",
            "note": "(*corresponding author)",
            "page": "847-867",
            "year": 2018,
            "url": "https://doi.org/10.1007/s11251-018-9461-5"
          },
          {
            "authors": ["Chao, P. Y.", "Lai, K. R.", "Liu, C. C.", "Lin, H. M."],
            "title": "Strengthening social networks in online discussion forums to facilitate help seeking for solving problems.",
            "ref": "Journal of Educational Technology & Society, 21(4)",
            "page": "39-50",
            "year": 2018,
            "url": ""
          },
          {
            "authors": ["Liu, C. C.", "Chen, Y. C.", "Tai, S.-J. D."],
            "title": "A social network analysis on elementary student engagement in the networked creation community.",
            "ref": "Computers & Education. 115",
            "page": "114-125",
            "year": 2017,
            "url": ""
          },
          {
            "authors": ["Chang, C.-J.", "Chang, M.-H.", "Liu, C.-C.*", "Chiu, B.-C.", "Fan Chiang, S.-H.", "Wen, C.-T.", "Hwang, F.-K.", "Chao, P.-Y.", "Chen, Y.-L.", "Chai, C.-S."],
            "title": "An analysis of collaborative problem-solving activities mediated by individual-based and collaborative computer simulations.",
            "ref": "Journal of Computer Assisted Learning, 33",
            "note": "(*corresponding author)",
            "page": "649-662",
            "year": 2017,
            "url": "https://doi.org/10.1111/jcal.12208"
          },
          {
            "authors": ["Chang, C.-J.", "Chang, M.-H.", "Chiu, B.-C.", "Liu, C.-C.*", "Fan Chiang, S.-H.", "Wen, C.-T.", "Hwang, F.-K.", "Wu, Y.-T.", "Chao, P.-Y.", "Lai, C.-H.", "Wu, S.-W.", "Chang, C.-K.", "Chen, W."],
            "title": "An analysis of student collaborative problem solving activities mediated by collaborative simulations.",
            "ref": "Computers & Education, 114",
            "page": "222-235",
            "year": 2017,
            "url": ""
          },
          {
            "authors": ["Liu, C. C.", "Chen, W. C.", "Lin, H. M.", "Huang, Y. Y."],
            "title": "A remix-oriented approach to promoting student engagement in a long-term participatory learning program.",
            "ref": "Computers & Education,110",
            "page": "1-15",
            "year": 2017,
            "url": ""
          },
          {
            "authors": ["Huang, Y. Y.", "Liu, C. C.*","Wang, Y.", "Tsai, C. C.", "Lin H. M."],
            "title": "Student engagement in long-term collaborative EFL storytelling activities: An analysis of learners with English proficiency differences.",
            "ref": "Educational Technology & Society, 20(3)",
            "note": "(*corresponding author)",
            "page": "95-109",
            "year": 2017,
            "url": ""
          },
          {
            "authors": ["Liu, C. C.", "Lu, K. H.", "Wu, L. Y.", "Tsai, C. C."],
            "title": "The Impact of Peer Review on Creative Self-efficacy and Learning Performance in Web 2.0 Learning Activities.",
            "ref": "Journal of Educational Technology & Society,19(2)",
            "page": "286-297",
            "year": 2016,
            "url": ""
          },
          {
            "authors": ["Liu, C. C.", "Wang, P. C.", "Tai, S. J. D."],
            "title": "An analysis of student engagement patterns in language learning facilitated by Web 2.0 technologies.",
            "ref": "ReCALL,28(02)",
            "page": "104-122",
            "year": 2016,
            "url": ""
          },
          {
            "authors": ["Chang, C. J.", "Liu, C. C.*", "Tsai, C. C."],
            "title": "Supporting Scientific Explanations with Drawings and Narratives on Tablet Computers: An Analysis of Explanation Patterns. ",
            "ref": "The Asia-Pacific Education Researcher, 25(1)",
            "note": "(*corresponding author)",
            "page": "173-184",
            "year": 2016,
            "url": ""
          },
          {
            "authors": ["Liu, C.-C.", "Lin, C.-C.*", "Deng, G.-Y.", "Tsai, C.-C."],
            "title": "Online knowledge sharing experience with Creative Commons.",
            "ref": "Online Information Review, 38(5)",
            "page": "680-696",
            "year": 2014,
            "url": ""
          },
          {
            "authors": ["Liu, C. -C.", "Wu, L. Y. -F.", "Chen, Z. -M.", "Tsai, C. -C. ","Lin, H. -M."],
            "title": "The effect of story grammars on creative self-efficacy and digital storytelling.",
            "ref": "Journal of Computer Assisted Learning.30(5)",
            "page": "450-464",
            "year": 2014,
            "url": ""
          },
          {
            "authors": ["Liu, C.-C.", "Lin, C.-C.", "Chang, C.-Y.", "Chao, P.-Y."],
            "title": "Knowledge Sharing among University Students Facilitated with a Creative Commons Licensing Mechanism: A Case Study in a Programming Course.",
            "ref": "Educational Technology & Society.17(3)",
            "page": "154-167",
            "year": 2014,
            "url": ""
          },
          {
            "authors": ["Liu, C. C.", "Tseng, K. H.", "Wu, L. Y."],
            "title": "A participatory learning framework for enhancing children’s reading experience with electronic book readers.",
            "ref": "Research and Practice in Technology Enhanced Learning, 8(1)",
            "page": "129-151",
            "year": 2013,
            "url": ""
          },
          {
            "authors": ["Liu C.-C.", "Chang C.-J."," Tseng J.-M."],
            "title": "The effect of recommendation systems on Internet-based learning for different learners: A data mining analysis. ",
            "ref": "British Journal of Educational Technology. 44: (SSCI) (IF= 2.098, rank= 12/206, Education & Educational research).",
            "page": "758-773",
            "year": 2013,
            "url": ""
          },
          {
            "authors": ["Chung C.-W.", "Lee C.-C.","Liu C.-C.*"],
            "title": "Investigating face-to-face peer interaction patterns in a collaborative Web discovery task: the benefits of a shared display.",
            "ref": "Journal of Computer Assisted Learning. 29: (SSCI) (IF= 1.464, 2011, rank= 27/206, Education & Educational research).",
            "page": "188–206",
            "year": 2013,
            "url": ""
          },
          {
            "authors": ["Chang C.-J.", "Tseng J.-M.","Liu C.-C.*"],
            "title": "The effect of semantic technologies on the exploration of the web of knowledge by female and male users.",
            "ref": "Electronic Library. (SSCI) (IF= 0.5, IF rank= 56/83, Information Science & Library Science)",
            "note": "(*corresponding author)",
            "page": "",
            "year": 2013,
            "url": ""
          },
          {
            "authors": ["Liu, C.-C.", "Tao, S.-Y.", "Chen, W.-H.", "Liu, B.-J.","Chen, S.-Y."],
            "title": "The effects of a creative commons approach on collaborative learning.",
            "ref": "Behaviour & Information Technology. (SSCI).",
            "page": "",
            "year": 2013,
            "url": ""
          },
          {
            "authors": ["Chang C.-J.", "Liu C.-C.*","Shen Y.-J."],
            "title": "Are one-to-one computers necessary an analysis of collaborative web exploration activities supported by shared displays.",
            "ref": "Educational Technology & Society, 15 (4), (SSCI) (IF= 1.011, rank= 58/206, Education & Educational research)",
            "note": "(*corresponding author)",
            "page": "3-13",
            "year": 2012,
            "url": ""
          },
          {
            "authors": ["Liu, C.-C.", "Liu, K.-P.", "Wang, B.-H.", "Chen, G.-D.","Su, M.-C."],
            "title": "Applying Tangible Story Avatars to Enhance Children's Collaborative Storytelling.",
            "ref": "British Journal of Educational Technology. (SSCI).",
            "page": "",
            "year": 2012,
            "url": ""
          },
          {
            "authors": ["Liu, C.-C.", "Cheng, Y.-B.", "Huang, C.-W."],
            "title": "The effect of simulation games on the learning of computational problem solving.",
            "ref": "Computers & Education, 57(3), (SSCI).",
            "page": "1907-1918",
            "year": 2011,
            "url": ""
          },
          {
            "authors": ["Liu, C.-C.", "Don, P.-H.", "Chung, C.-W.", "Lin, S.-J", "Chen G.-D.", "Liu, B.-J."],
            "title": "Contributing, Exchanging and Linking for Learning: Supporting Web Co-Discovery in One-to-One Environments.",
            "ref": "Educational Technology & Society, 13(4), (SSCI).",
            "page": "126-139",
            "year": 2011,
            "url": ""
          },
          {
            "authors": ["Liu, C.-C.", "Liu, K.-P.", "Chen, W.-H.", "Lin, C.-P.","Chen, G.-D."],
            "title": "Collaborative Storytelling Experiences in Social Media: Influence of Peer-Assistance Mechanisms.",
            "ref": " Computers & Education, 57(2),(SSCI).",
            "page": "1544-1556",
            "year": 2011,
            "url": ""
          },
          {
            "authors": ["Liu, C.-C.", "Chen, S.-L.", "Shih, J.-L.", "Huang, G.-T.","Liu, B.-J."],
            "title": "An Enhanced Concept Map Approach to Improving Children's Storytelling Ability.",
            "ref": " Computers & Education, 56,(SSCI).",
            "page": "873-884",
            "year": 2011,
            "url": ""
          },
          {
            "authors": ["Liu, C.-C.","Milrad, M."],
            "title": "Guest Editorial - One-to-One Learning in the Mobile and Ubiquitous Computing Age.",
            "ref": "Educational Technology & Society, 13(4)",
            "page": "1-3",
            "year": 2010,
            "url": ""
          },
          {
            "authors": ["Liu, C.-C.", "Chiang, S.-H.", "Chou, C.-Y.","Chen, S.-Y."],
            "title": "Knowledge exploration with concept association techniques.",
            "ref": "Online Information Review, 34(5),(SSCI).",
            "page": "786-805",
            "year": 2010,
            "url": ""
          },
          {
            "authors": ["Lin, W.-T.", "Liu C.-C.","Chen, S.-Y."],
            "title": "Fast and robust iris recognition.",
            "ref": "The Imaging Science Journal, 57(3), (SCI, EI).",
            "page": "128-139",
            "year": 2009,
            "url": ""
          },
          {
            "authors": ["Liu, C.-C.", "Chung, C.-W.", "Chen, N.-S.","Liu, B.-J."],
            "title": "Analysis of peer interaction in learning activities with personal handhelds and shared displays.",
            "ref": "Educational Technology & Society, 12(3), (SSCI).",
            "page": "127-142",
            "year": 2009,
            "url": ""
          },
          {
            "authors": ["Liu, C.-C.", "Tao, S.-Y.","Nee, J.-N."],
            "title": "Bridging the gap between students and computers: supporting activity awareness for network collaborative learning with GSM network.",
            "ref": " Behaviour and Information Technology, 27(2), (SSCI).",
            "page": "127-137",
            "year": 2008,
            "url": ""
          },
          {
            "authors": ["Liu, C.-C." ,"Tsai, C.-C."],
            "title": " An analysis of peer interaction patterns as discoursed by on-line small group problem-solving activity.",
            "ref": "Computers & Education, 50(3), (SSCI).",
            "page": "627-639",
            "year": 2008,
            "url": ""
          },
          {
            "authors": ["Huang, Y.-W.", "Liu, C.-C.","Chen, S.-Y."],
            "title": "Graph/Image legend retrieval.",
            "ref": "Asian journal of Health and Information Sciences, 2(1-4)",
            "page": "79-102",
            "year": 2007,
            "url": ""
          },
          {
            "authors": ["Liu, C.-C.","Chung, C.-W."],
            "title": "Detecting mouse movement with repeated visit patterns for retrieving noticed knowledge components on web pages.",
            "ref": "IEICE Transactions on Information and Systems, 90(10), (SCI).",
            "page": "1687-1696",
            "year": 2007,
            "url": ""
          },
          {
            "authors": ["Liu, C.-C." ,"Kao, L.-C."],
            "title": "Do handheld devices facilitate face-to-face collaboration? Handheld devices with large shared display groupware to facilitate group interactions.",
            "ref": "Journal of Computer Assisted Learning, 23(4), (SSCI).",
            "page": "285-299",
            "year": 2007,
            "url": ""
          },
          {
            "authors": ["Liu, C.-C.","Hong, Y.-C."],
            "title": "Providing hearing-impaired students with learning care after classes through smart phones and GPRS network.",
            "ref": "British Journal of Educational Technology, 38(4), (SSCI).",
            "page": "727-741",
            "year": 2007,
            "url": ""
          },
          {
            "authors": ["Liu, C.-C.", "Chou, C.-C.", "Liu, B.-J.","Yang, J.-W."],
            "title": "Improving mathematics teaching and learning experiences for hard of hearing students with wireless technology enhanced classrooms.",
            "ref": "American Annals of The Deaf, 151(3),(SSCI).",
            "page": "345-355",
            "year": 2006,
            "url": ""
          },
          {
            "authors": ["Liu, C.-C.","Lee, J.-H."],
            "title": "rompting conceptual understanding with computer-mediated peer discourse and knowledge acquisition techniques.",
            "ref": "British Journal of Educational Technology, 36(5), (SSCI).",
            "page": "821-837",
            "year": 2005,
            "url": ""
          },
          {
            "authors": ["Liu C.-C.","Tsai, C.-M."],
            "title": "Peer assessment through web-based knowledge acquisition: tools to support conceptual awareness.",
            "ref": "Innovations in Education and Teaching International, 42(1), (SSCI).",
            "page": "43-59",
            "year": 2005,
            "url": ""
          },
          {
            "authors": ["Liu, C.-C.", "Don, P.-H.","Tsai, C.-M."],
            "title": "Assessment based on linkage patterns in concept maps.",
            "ref": "Journal of Information Science and Engineering (E-Learning Special Issue), 21(5), (SSCI, SCI).",
            "page": "873-890",
            "year": 2005,
            "url": ""
          },
          {
            "authors": ["Lin, T.-C.", "Liu, R.-S.", "Liu, C.-C.", "Chen, C.-Y."," Chen, S.-Y."],
            "title": "Grey modeling the gene expressions applied to microarray data classification.",
            "ref": "The Journal of Grey System, 17(1)",
            "page": "7-22",
            "year": 2005,
            "url": ""
          },
          {
            "authors": ["Liu, C.-C.", "Chen, G.-D.", "Wang, C.-Y.","Lu, C.-F."],
            "title": "Student performance assessment using Bayesian network and web portfolios.",
            "ref": "Journal of Educational Computing Research, 27(4)",
            "page": "435-467",
            "year": 2002,
            "url": ""
          },
          {
            "authors": ["Wang, C.-Y.", "Ou, K.-L.", "Liu, C.-C.", "Liu, B.-J.","Chen, G.-D."],
            "title": "Using a text miner to identify group roles of students in a web-based learning system.",
            "ref": "International Journal of Learning, 9",
            "page": "",
            "year": 2002,
            "url": ""
          },
          {
            "authors": ["Chen, G.-D.", "Ou, K.-L.", "Liu, C.-C.","Liu, B.-J."],
            "title": "Intervention and strategy analysis for web group-learning.",
            "ref": "Journal of Computer Assisted Learning, 17(1), (SSCI)",
            "page": "58-71",
            "year": 2001,
            "url": ""
          },
          {
            "authors": ["Chen, G.-D.", "Liu, C.-C.", "Ou, K.-L.","Lin, M.-S."],
            "title": "Web learning portfolios: a tool for supporting performance awareness.",
            "ref": "Innovations in Education and Training International, 38(1), (SSCI).",
            "page": "19-30",
            "year": 2001,
            "url": ""
          },
          {
            "authors": ["Chen, G.-D.", "Liu, C.-C.", "Ou, K.-L.", "Liu, B.-J."],
            "title": "Discovering decision knowledge from web log portfolio for managing classroom processes by applying decision tree and data cube technology.",
            "ref": "Journal of Educational Computing Research, 23(3), (SSCI).",
            "page": "305-332",
            "year": 2000,
            "url": ""
          }
        ]
      }
    ]
  }
  