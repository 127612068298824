import { useTranslation } from "react-i18next";

export const CreativeLearning = () => {
	const { t } = useTranslation();

	return {
    title: t("page.research.creative_learning.title"), 
    logo: "/images/research/creative_learning/logo.png",
    description: t("page.research.creative_learning.description"),
    images: [
			{
				desc: t("page.research.creative_learning.images.0.desc"),
				imgURL:"/images/research/creative_learning/interface.png"
			},
			{
				desc: t("page.research.creative_learning.images.1.desc"),
				imgURL: "/images/research/creative_learning/drawpanel.png"
			}
    ]
	}
}