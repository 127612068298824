import { useTranslation } from "react-i18next";

export const Cscl = () => {
    const { t } = useTranslation();

    return{
        title: t("page.research.cscl.title"),
        description: t("page.research.cscl.description"),
        images: [
            {
                desc: t("page.research.cscl.images.0.desc"),
                imgURL:"/images/research/cscl/transparent_monitor.png"
            },
            {
                desc: t("page.research.cscl.images.1.desc"),
                imgURL:"/images/research/cscl/HoloLens.png"
            },
        ]
    }
}