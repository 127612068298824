import React from 'react';
import { Box } from '@mui/material';

const ConferenceList = ({ data }) => {
  return (
    <Box padding={2}>
      {data.sections.map((section, index) => (
        <div key={index}>
          {section.sectionTitle && <h6>{section.sectionTitle}</h6>}
          <ol>
            {section.publications.map((publication, pubIndex) => (
              <li key={pubIndex} style={{ lineHeight: '24px', paddingLeft: '36px', textIndent: '-25px', fontSize: '20px', marginBottom: '10px' }}>
                {publication.author} ({publication.year}{publication.month ? `, ${publication.month}` : ''}) {publication.title}{', '}
                {publication.conference} {publication.location ? `, ${publication.location}`: ''}{publication.page ? `, ${publication.page}` : ''} {publication.time ? `, ${publication.time}` : ''}.
                {publication.status ? `(${publication.status})` : ''}
              </li>
            ))}
          </ol>
        </div>
      ))}
    </Box>
  );
};

export default ConferenceList;
