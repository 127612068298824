export const members_97 = [
    {
        imgURL: "/images/members/97/Members_ms_9701.png",
        name: {
            chinese: "黃嘉文",
            english: "Chia-Wen Huang"
        },
        email: "j121780im@gmail.com",
        spec: "C/C++、ASP、VB、C#、Flash ActionScript、SQL、XNA Framework"
    },
    {
        imgURL: "/images/members/97/Members_ms_9702.png",
        name: {
            chinese: "李中正",
            english: "Nick Lee"
        },
        email: "nicklee@cl.ncu.edu.tw",
        spec: "Linux, Windows 伺服器架設管理, SQL, ASP.NET ,C#, XNA"
    },
    {
        imgURL: "/images/members/97/Members_ms_9703.png",
        name: {
            chinese: "李致中",
            english: "Johnny Lee"
        },
        email: "naje0522@gmail.com",
        spec: "PHP, JAVA, C, SQL"
    },
    {
        imgURL: "/images/members/97/Members_ms_9704.png",
        name: {
            chinese: "游曼欣",
            english: "Man-Hsin Yu"
        },
        email: "kaness571@gmail.com",
        spec: "VB.NET, JAVA"
    },
    {
        imgURL: "/images/members/97/Members_ms_9705.png",
        name: {
            chinese: "洪國竣",
            english: "kuo-chun Hung"
        },
        email: "cruise0817@yahoo.com.tw",
        spec: "VB.NET, ASP.NET, SQL, C#, Silverlight"
    },
    {
        imgURL: "/images/members/97/Members_ms_9706.png",
        name: {
            chinese: "黃志新",
            english: "chih-hsin Huang"
        },
        email: "wayne1208@gmail.com",
        spec: "ASP.NET, SQL, C#, Silverlight"
    }
]