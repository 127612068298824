export const members_100 = [
    {
        imgURL: "/images/members/100/Members_100_r1_c1.jpg",
        name: {
            chinese: "蕭伊茹",
            english: "Hsiao I Ju"
        },
        email: "acatinroom@cl.ncu.edu.tw",
        spec: "Java, JSP, ASP.net, html網頁設計"
    },
    {
        imgURL: "/images/members/100/Members_100_r1_c2-1.jpg",
        name: {
            chinese: "鄭如婷",
            english: "Ju Ting Cheng"
        },
        email: "happykyoko@cl.ncu.edu.tw",
        spec: "visual C++, MySQL, Apache, CCNA"
    },
    {
        imgURL: "/images/members/100/Members_100_r1_c3.jpg",
        name: {
            chinese: "黃俊凱",
            english: "Huang Chun Kai"
        },
        email: "網頁設計",
        spec: "ah73608@gmail.com"
    },
    {
        imgURL: "/images/members/100/Members_100_r1_c4.jpg",
        name: {
            chinese: "陳科瑞",
            english: "Ke-Jui Chen"
        },
        email: "ray055137ray@msn.com",
        spec: "Java, Action Script, VB.NET,  PHP, Apache, MySQL, Access"
    },
    {
        imgURL: "/images/members/100/Members_100_r1_c5.jpg",
        name: {
            chinese: "陳冠妤",
            english: "Kuan-Yu Cheni"
        },
        email: "kuanyu715@gmail.com",
        spec: "C, Java, 網頁設計"
    }
]