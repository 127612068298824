import { useTranslation } from "react-i18next";

export const GameBase = () => {
  const { t } = useTranslation();

  return {
    title: t("page.research.game_base.title"),
    description : t("page.research.game_base.description"),
    images: [
      {
        desc: t("page.research.game_base.images.0.desc"),
        imgURL: "/images/research/game_base/interface.jpg"
      },
      {
        desc: t("page.research.game_base.images.1.desc"),
        imgURL: "/images/research/game_base/programming.jpg"
      }
    ]
  }
}
