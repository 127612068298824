import React from 'react';
import { Icon } from '@iconify/react'; 
import { styled } from '@mui/material/styles'; // 使用 MUI 的 styled 函數

const Card = styled('div')(({ theme, color, hoverColor }) => ({
  height: '30vh', // 設置卡片高度
  padding: '20px',
  backgroundColor: '#fafafa', // 卡片背景色
  borderRadius: '15px', // 卡片圓角
  boxShadow: '0 5px 8px rgba(0, 0, 0, 0.3)', // 卡片陰影
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  transition: 'background-color 0.2s ease, color 0.2s ease', // 平滑顏色過渡

  // 初始圖標和文本顏色
  color: color,

  // 鼠標懸停效果
  '&:hover': {
    color: hoverColor, // 鼠標懸停時圖標和文字顏色
  },

  // 鼠標懸停時的圖標顏色
  '&:hover svg': {
    color: hoverColor,
  },
}));

const CardComponent = ({ icon, zh_title, en_title, color, hoverColor, onClick }) => {
  return (
    <Card onClick={onClick} color={color} hoverColor={hoverColor}>
      <Icon icon={icon} style={{ fontSize: '50px', marginBottom: '10px', fontFamily: 'GenSanRounded' }} />
      <span style={{ fontSize: '20px', fontWeight: 'bold', fontFamily: 'GenSanRounded', marginBottom: '5px' }}>{zh_title}</span>
      <span style={{ fontSize: '17px' }}>{en_title}</span>
    </Card>
  );
};

export default CardComponent;
