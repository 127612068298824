import React from "react";

import Layout from "../../component/Layout";
import MemberCard from "../../component/MemberCard";
import { Postdoctor } from "./info/postdoctor";

import { Grid } from "@mui/material";

const PostDoc = () => {
    const postdoctor = Postdoctor();
    // 找出 postdoctor 中資料最多的一筆，計算最大高度
    const maxContentLength = Math.max(...postdoctor.map((postdoctor) => {
        return(
            (Math.ceil(postdoctor.name.chinese?.length / 31) || 0) +
            (Math.ceil(postdoctor.name.english?.length / 31) || 0) +
            (Math.ceil(postdoctor.email?.length / 31) || 0) +
            (Math.ceil(postdoctor.workConetent?.length / 31) || 0) +
            (Math.ceil(postdoctor.spec?.length / 31) || 0) +
            (Math.ceil(postdoctor.officePhone?.length / 31) || 0)
        );
    }))

    const minHeight = (maxContentLength * 21) + 325;
    return (
        <Layout>
            <Grid container spacing={3} padding={2}>
            {postdoctor.map((postdoctor, index) => (
                    <Grid item key={index}>
                        <MemberCard
                            minHeight={`${minHeight}px`}
                            imgURL={postdoctor.imgURL}
                            name={postdoctor.name}
                            email={postdoctor.email}
                            workContent={postdoctor.workContent}
                            spec={postdoctor.spec}
                        >
                        </MemberCard>
                    </Grid>
                ))}
            </Grid>
        </Layout>
    )
}

export default PostDoc