import React from 'react';
import { Box } from '@mui/material';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ children }) => {
    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflowY: 'auto', 
    };

    const contentStyle = {
        flex: '1',
    };

    return (
        <Box sx={containerStyle}>
            <Header />
            <Box sx={contentStyle}>
                {children}
            </Box>
            <Footer />
        </Box>
    );
};

export default Layout;
