export const members_96 = [
    {
        imgURL: "/images/members/96/Members_96_r5_c4.png",
        name: {
            chinese: "賴伯毅",
            english: "Jerry Lai"
        },
        email: "genius.fool2419@gmail.com",
        spec: "PHP, JAVA, C"
    },
    {
        imgURL: "/images/members/96/Members_96_r6_c1.png",
        name: {
            chinese: "陳威宏",
            english: "Sentinen Chen"
        },
        email: "sentinen@yahoo.com.tw",
        spec: "C++, C#, flash"
    },
    {
        imgURL: "/images/members/96/Members_96_r7_c4.png",
        name: {
            chinese: "莊佳霖",
            english: "Chia-Lin Chuang"
        },
        email: "wasibear@gmail.com",
        spec: "C, PHP, C#, JAVA"
    },
    {
        imgURL: "/images/members/96/Members_96_r8_c1.png",
        name: {
            chinese: "沈彥志",
            english: "Yan-Jhih Shen"
        },
        email: "gegeya1@gmail.com",
        spec: "C, PHP, JAVA"
    },
    {
        imgURL: "/images/members/96/Members_96_r9_c4.png",
        name: {
            chinese: "陳微虹",
            english: "Phoebe Chen"
        },
        email: "phoebencu@gmail.com",
        spec: "VB.NET, MS SQL"
    },
    {
        imgURL: "/images/members/96/Members_96_r10_c1.png",
        name: {
            chinese: "李訓桐",
            english: "Xun-Ton,Lee"
        },
        email: "s946063@mail.yzu.edu.tw",
        spec: "C＃"
    },
    {
        imgURL: "/images/members/96/Members_96_r11_c4.png",
        name: {
            chinese: "黃國庭",
            english: "Guo-Ting,Huang"
        },
        email: "huang19850101@gmail.com",
        spec: "JAVA, VB.NET, C++.NET, MYSQL"
    }
]