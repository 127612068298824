export const conference = {
    "sections": [
        {
            "publications": [
                {
                    "author": "Snyder C., Wen C. T., Hutchins N. M., Vatral C., Liu C. C., Biswas G.",
                    "year": 2024,
                    "month": "",
                    "title": "Investigating Collaborative Problem Solving Behaviors during STEM+C Learning in Groups with Different, Prior Knowledge Distributions",
                    "conference": "17th International Conference on Computer-Supported Collaborative Learning (CSCL), 107-114. (Best long paper award)",
                    "location": ""
                },
                {
                    "author": "Feng, Y. T., Liu, C. C., & Chang, C. H.",
                    "year": 2023,
                    "month": "July",
                    "title": "The design and analysis of a storytelling chatbot with natural language processing techniques for enhancing EFL reading",
                    "conference": "In 2023 IEEE International Conference on Advanced Learning Technologies (ICALT) (pp. 250-251)",
                    "location": "IEEE"
                },
                {
                    "author": "曾鉉閎、鄭嘉惠、溫采婷、劉晨鐘",
                    "year": 2023,
                    "month": "",
                    "title": "探討漸進式學習方式對學生於計算建模平台學習之影響",
                    "conference": "第十八屆台灣數位學習發展研討會，墾丁，中華民國。(最佳論文獎)",
                    "location": "墾丁，中華民國"
                },
                {
                    "author": "吳一平、鄭嘉惠、劉晨鐘",
                    "year": 2023,
                    "month": "",
                    "title": "教育元宇宙之合作與學習行為分析",
                    "conference": "第十八屆台灣數位學習發展研討會",
                    "location": "墾丁，中華民國"
                },
                {
                    "author": "林彥宇、鄭嘉惠、劉晨鐘",
                    "year": 2023,
                    "month": "",
                    "title": "融合遊戲化與元宇宙的學習環境對國小學生英語閱讀興趣影響",
                    "conference": "第十八屆台灣數位學習發展研討會",
                    "location": "墾丁，中華民國"
                },
                {
                    "author": "陳宛君、劉晨鐘",
                    "year": 2023,
                    "month": "",
                    "title": "教中學問答機器人對學生英語閱讀興趣影響",
                    "conference": "第十八屆台灣數位學習發展研討會",
                    "location": "墾丁，中華民國"
                },
                {
                    "author": "陳俊豪、溫采婷、張銘華、劉晨鐘、鄭嘉惠、張貴雲、戴宏明、蔡宇翔",
                    "year": 2023,
                    "month": "",
                    "title": "透明顯示器於合作科學探究學習之影響與限制",
                    "conference": "第十八屆台灣數位學習發展研討會(最佳論文獎)",
                    "location": "墾丁，中華民國"
                },
                {
                    "author": "賴柏翰，劉晨鐘，鄭嘉惠，溫采婷",
                    "year": 2021,
                    "month": "March",
                    "title": "學生應用視覺化建模與程式工具進行計算建模之分析",
                    "conference": "第十六屆台灣數位學習發展研討會",
                    "location": "宜蘭，臺灣"
                },
                {
                    "author": "賴柏翰，劉晨鐘，鄭嘉惠，溫采婷",
                    "year": 2021,
                    "month": "March",
                    "title": "學生應用視覺化建模與程式工具進行計算建模之分析",
                    "conference": "第十六屆台灣數位學習發展研討會",
                    "location": "宜蘭，臺灣"
                },
                {
                    "author": "Hsieh, I. C., Liu, C. C., Tsai, M. J., Wen, C. T., Chang, M. H., Chiang, S. H. F., & Chang, C. J.",
                    "year": 2019,
                    "month": "September",
                    "title": "The Analysis of Collaborative Science Learning with Simulations Through Dual Eye-Tracking Techniques",
                    "conference": "International Conference on Collaboration and Technology (pp. 36-44).",
                    "location": "Springer, Cham"
                },
                {
                    "author": "Wen, C. T., Chang, C. J., Hwang, S. C., Chang, M. H., Fan Chiang, S. H., Liu, C. C., Hwang, F. K., Chang, H.Y., Yang, C. W.",
                    "year": 2019,
                    "month": "September",
                    "title": "An analysis of student inquiry performances in computer supported light-weighted collaborative experiment",
                    "conference": "The Second International Cognitive Cities Conference (IC3 2019)",
                    "location": "Kyoto, Japan"
                },
                {
                    "author": "Wen, C. T., Chen, S. M., Liu, C. C., Chang, C. J., Chang, M. H., Fan Chiang, S. H., Hwang, F. K., Chang, H.Y., Yang, C. W.",
                    "year": 2019,
                    "month": "November",
                    "title": "The impact of sensory simulations on young children’s science learning",
                    "conference": "The 27th International Conference on Computers in Education (ICCE 2019)",
                    "location": "Kenting, Taiwan"
                },
                {
                    "author": "Chang, C.-J., Wen, C.-T., Tseng L.-W., Chang, M.-H., Liu, C.-C., Fan Chiang, S.-H., Hwang, F.-K.",
                    "year": 2018,
                    "month": "December",
                    "title": "The impact of inquiry-based instruction with inquiry map on conceptions of learning science to learning science",
                    "conference": "The 26th International Conference on Computers in Education (ICCE 2018)",
                    "location": "Metro Manila, Philippines"
                },
                {
                    "author": "温采婷，張家榮，黃思綺，張銘華，范姜士燻，黃福坤，劉晨鐘",
                    "year": 2019,
                    "month": "March",
                    "title": "輕實驗探究教學環境對學生科學學習之影響",
                    "conference": "第十四屆台灣數位學習發展研討會(TWELF 2019)",
                    "location": "Chiayi, Taiwan"
                },
                {
                    "author": "温采婷、張家榮、張銘華、范姜士燻、劉晨鐘、黃福坤、陳彥良",
                    "year": 2017,
                    "month": "June",
                    "title": "探究模擬遊戲對學生科學學習之學習表現、觀點與行為之影響",
                    "conference": "The 21st Global Chinese Conference on Computers in Education(GCCCE 2017)",
                    "location": "Beijing, China"
                },
                {
                    "author": "鄭登耀、吳穎沺、劉晨鐘、張家榮、張銘華、溫采婷、王立仁",
                    "year": 2017,
                    "month": "June",
                    "title": "運用合作科學問題解決學習平台探討科學教師的科學模擬活動之歷程與表現",
                    "conference": "The 21st Global Chinese Conference on Computers in Education(GCCCE 2017)",
                    "location": "Beijing, China"
                },
                {
                    "author": "Chang, C.-J., Liu, C.-C., Wu, Y.-T., Chang, M.-H., Fan Chiang, S.-H., Chiu, B.-C., Wen, C.-T., Hwang, F.-K., Chao, P.-Y., Lai, C.-H., Wu, S.-W., & Chang, C.-K.",
                    "year": 2016,
                    "month": "November",
                    "title": "Students’ perceptions on problem solving with collaborative computer simulation",
                    "conference": "The 24th International Conference on Computers in Education (ICCE 2016)",
                    "location": "Bombay, India"
                },
                {
                    "author": "王妤、張家榮、劉晨鐘",
                    "year": 2015,
                    "month": "December",
                    "title": "探討Web 2.0數位合作說故事對不同英語熟練程度學生的影響",
                    "conference": "2015全國計算機會議(NCS 2015)",
                    "location": "Pingtung, Taiwan"
                },
                {
                    "author": "劉晨鐘、吳穎沺、張銘華、張家榮、范姜士燻、邱秉誠、黃福坤、趙伯堯、張志康、賴佳禧、吳素妏",
                    "year": 2015,
                    "month": "November",
                    "title": "電腦模擬支援合作科學問題解決",
                    "conference": "第十一屆台灣數位學習發展研討會(TWELF 2015)",
                    "location": "Kaohsiung, Taiwan"
                },
                {
                    "author": "Chang, C.-J., Liu, C.-C., & Tsai, C.-C.",
                    "year": 2015,
                    "month": "November",
                    "title": "Drawing-based Learning using imagination in science class",
                    "conference": "The 23rd International Conference on Computers in Education(ICCE 2015)",
                    "location": "Hangzhou, China"
                },
                {
                    "author": "張家榮、劉晨鐘",
                    "year": 2015,
                    "month": "January",
                    "title": "Developing an iPad app to support scientific explanation",
                    "conference": "第十七屆海峽兩岸資訊技術研討會(CSIT 2015)",
                    "location": "Taoyuan, Taiwan"
                },
                {
                    "author": "Chia-Ching Lin, Fo-Jui Chen, Chen-Chung Liu",
                    "year": 2014,
                    "title": "Applying POE framework in a simulation system for facilitating physics learning with Tablet PCs",
                    "conference": "The 22nd International Conference on Computers in Education(ICCE 2014)",
                    "location": "Nara, Japan"
                },
                {
                    "author": "Liu, K.-P. & Liu, C.-C.",
                    "year": 2013,
                    "month": "November",
                    "title": "Facilitating EFL with storytelling on tablet PCs",
                    "conference": "The 21st International Conference on Computers in Education (ICCE 2013)",
                    "location": "Denpasar Bali, Indonesia"
                },
                {
                    "author": "Liu, C.-C., Wu, Y.- F., Lu, K.-H. & Lin, C.-C.",
                    "year": 2012,
                    "month": "November",
                    "title": "Supporting creativity learning in digital storytelling with tablet computers: a peer assessment approach",
                    "conference": "The 20th International Conference on Computers in Education (ICCE 2012)",
                    "location": "Singapore"
                },
                {
                    "author": "Tseng, K.-H., Liu, C.-C. & Liu, B.-J.",
                    "year": 2012,
                    "month": "March",
                    "title": "Scaffolded participatory and collaborative learning: Enhancing children reading with E-book readers",
                    "conference": "The 7th IEEE International Conference on Wireless, Mobile and Ubiquitous Technology in Education(WMUTE 2012)",
                    "location": "Kagawa, Japan"
                },
                {
                    "author": "Chen-Wei Chung, Wang-Hsin Kuo, Chen-Chung Liu",
                    "year": 2010,
                    "title": "Facilitating Group Learning in Science Laboratory Courses Using Handheld Devices",
                    "conference": " 9th International Conferences of the Learning Sciences, Vol. 1, 182-189",
                    "location": "Chicago, USA"
                },
                {
                    "author": "Shu-Yuan Tao, Wei-Hung Chen, Chen-Chung Liu, Baw-Jhiune Liu, and Tsung-Yen Chuang.",
                    "year": 2010,
                    "title": "Applying Social Media for Encouraging Mutual Support and Social Creativity",
                    "conference": "The 18th International Conference on Computers in Education (ICCE2010)",
                    "location": "Putrajaya, Malaysia",
                    "time": "November 29 - December 3, 2010"
                },
                {
                    "author": "Chia-Jung Chang, Kuo-Ping Liu & Chen-Chung Liu.",
                    "year": 2010,
                    "title": "Applying a Proposed Recommendation System to Facilitate Web Search in Professional Community",
                    "conference": "The 18th International Conference on Computers in Education (ICCE2010)",
                    "location": "Putrajaya, Malaysia",
                    "time": "November 29 - December 3, 2010"
                },
                {
                    "author": "Liu KP, Liu CC, Huang CH, Hung KC, Chang CJ",
                    "year": 2010,
                    "title": "An Audio Book Platform for Early EFL Oral Reading Fluency",
                    "conference": "The 10th IEEE International Conference on Advanced Learning Technologies (ICALT 2010)",
                    "location": "Tunisia",
                    "time": "2010"
                },
                {
                    "author": "陶淑瑗、陳微虹、劉晨鐘、莊宗嚴、劉寶鈞",
                    "year": 2010,
                    "title": "Applying Social Media for Encouraging Creativity",
                    "conference": "The 14th Annual Global Chinese Conference on Computers in Education (GCCCE2010)",
                    "location": "Singapore"
                },
                {
                    "author": "Huang, CS, Liu KP, Liu CC, Hung KJ and Liao YD",
                    "year": 2010,
                    "title": "A Study of Using Networked Peer Assessment For English Oral Reading Fluency of Elementary School Students",
                    "conference": "The International Conference on Computer and Network Technologies in Education",
                    "location": "Taiwan",
                    "time": "2010"
                },
                {
                    "author": "Hung KJ, Liu KP, Liu CC, Huang, CS and Tao SY",
                    "year": 2010,
                    "title": "An On-Line Storytelling Platform to Motivate Children’s English Oral Reading",
                    "conference": "The International Conference on Computer and Network Technologies in Education",
                    "location": "Taiwan",
                    "time": "2010"
                },
                {
                    "author": "Chia-Jung Chang, Chen-Chung Liu, Kuo-Ping Liu",
                    "year": 2010,
                    "title": "Applying Shared-Display Groupware for Supporting One-to-One Web Co-discovery",
                    "conference": "The Fourth Mobile and Ubiquitous Technologies Enhanced Learning Conference",
                    "location": "Taiwan",
                    "time": "2010"
                },
                {
                    "author": "Chia-Jung Chang, Chen-Chung Liu, Gwo-Dong Chen, Chin-Yuan Liu",
                    "year": 2010,
                    "title": "One-to-One with Shared-Display Groupware for Supporting Face-to-Face Web Co-discovery",
                    "conference": "World Conference on Educational Sciences",
                    "location": "Turkey",
                    "time": "2010"
                },
                {
                    "author": "Kuo-Ping Liu, Chen-Chung Liu, Gwo-Dong Chen, Baw-Jhiune Liu",
                    "year": 2010,
                    "title": "Children’s collaborative storytelling with linear and nonlinear approaches",
                    "conference": "World Conference on Educational Sciences",
                    "location": "Turkey",
                    "time": "2010"
                },
                {
                    "author": "Chen-Chung Liu, Kuo-Ping Liu",
                    "year": 2009,
                    "title": "Applying Tangible Story Avatars to Enhance Children’s Collaborative Storytelling",
                    "conference": "The 5th International Workshop on Mobile and Ubiquitous Learning Environments",
                    "location": "Japan",
                    "time": "2009"
                },
                {
                    "author": "Chen-Chung Liu, Chen-Wei Chung, Shu-Yuan Tao",
                    "year": 2009,
                    "title": "Making classrooms socio-technical environments for supporting collaborative learning: the role of personal devices and boundary",
                    "conference": "8th Computer Supported Collaborative Learning, Vol. 1, 320-324",
                    "location": "Rhodes, Greece",
                    "time": "2009"
                },
                {
                    "author": "Chen-Chung Liu, Ping-Hsing Don, Chen-Wei Chung, Shao-Jun Lin",
                    "year": 2009,
                    "title": "Contributing, Exchanging and Linking for Learning: Supporting Web Co-Discovery in One-to-One Environments",
                    "conference": "Educational Technology & Society, 13(4)",
                    "page": "126-139"
                },
                {
                    "author": "Allan Collins, Gerhard Fischer, Brigid Barron, Chen-Chung Liu, Hans Spada",
                    "year": 2009,
                    "title": "Long-Tail Learning: A unique opportunity for CSCL?",
                    "conference": "Proceedings of Computer Supported Collaborative Learning, Vol. 2, 22-24",
                    "time": "2009"
                },
                {
                    "author": "Chen-Chung Liu",
                    "year": 2008,
                    "title": "Beyond the ownership of handheld devices: active learning with ubiquitous learning minds",
                    "conference": "The Fifth IEEE International Conference on Wireless, Mobile, and Ubiquitous Technology in Education",
                    "location": "Beijing, China",
                    "time": "2008"
                },
                {
                    "author": "Shu-Yuan Tao, Kuang-Wen Ho, Chen-Wei Chung, Baw-Jhiune Liu and Chen-Chung Liu",
                    "year": 2008,
                    "title": "Designing a Groupware with Handheld Devices for Learning Mathematics",
                    "conference": "The Fifth IEEE International Conference on Wireless, Mobile, and Ubiquitous Technology in Education",
                    "location": "Beijing, China",
                    "time": "2008"
                },
                {
                    "author": "Chen-Wei Chung, Wang-Hsin Kuo, Chen-Chung Liu",
                    "year": 2008,
                    "title": "An analysis of group regulation patterns in collaborative inquiry learning",
                    "conference": "The 16th International Conference on Computers in Education (ICCE2008)",
                    "location": "Taipei, Taiwan",
                    "time": "2008"
                },
                {
                    "author": "Shu-Yuan Tao, Chu-Hao Chou, Chen-Chung Liu, Baw-Juiune Liu",
                    "year": 2008,
                    "title": "Exploring the Effect of Collaborative Storytelling for Hearing-impaired Students",
                    "conference": "The 16th International Conference on Computers in Education(ICCE2008)",
                    "location": "Taipei, Taiwan",
                    "time": "2008"
                },
                {
                    "author": "Chen-Chung Liu, Chen-Wei Chung, Yu-Lin Weng, Cheng-Yu Wu and Pei-Ting Kao",
                    "year": 2007,
                    "title": "Handheld devices supporting shared representation and interaction in collaborative learning -- Collaborative learning classroom and shared-display groupware design",
                    "conference": "Proceedings of GCCCE (2007)",
                    "location": "Cantonese, China",
                    "time": "2007"
                },
                {
                    "author": "Chen-Chung Liu, Shu-Yuan Tao, Kuang-Wen Ho, Baw-Jhiune Liu and Ching-Chi Hsu",
                    "year": 2007,
                    "title": "Constructing an MCSCL Groupware to Improve the Problem-solving Experience of Mathematics for Hearing-impaired Students",
                    "conference": "The 7th IEEE International Conference on Advanced Learning Technologies",
                    "location": "Niigata, Japan",
                    "time": "2007"
                },
                {
                    "author": "Chen-Chung Liu, Chen-Wei Chung, Yu-Lin Weng, Cheng-Yu Wu and Pei-Ting Kao",
                    "year": 2007,
                    "title": "An Interaction Study of Learning with Handhelds and Large Shared-Displays in Technology-Enriched Collaborative Classroom",
                    "conference": "The 7th IEEE International Conference on Advanced Learning Technologies",
                    "location": "Niigata, Japan",
                    "time": "2007"
                },
                {
                    "author": "劉晨鐘, 鍾陳威, 翁瑜璘, 吳政育, 高珮庭, 劉寶鈞",
                    "year": 2007,
                    "title": "以行動載具支援合作學習之知識表現與互動—合作學習教室與共同顯示群組軟體設計",
                    "conference": "第十一屆全球華人計算機教育應用大會(GCCCE2007), 428-435",
                    "location": "廣州, 中國"
                },
                {
                    "author": "Robert K. Lai, Chung-Hsien Lan, Chen-Chung Liu",
                    "year": 2006,
                    "title": "Application of Agent Negotiation in Supporting Adaptive Learning",
                    "conference": "The 6th IEEE International Conference on Advanced Learning Technologies, 232-236",
                    "location": "Kerkrade, The Netherlands",
                    "time": "2006"
                },
                {
                    "author": "Chen-Chung Liu ,Wen-Chi Chiou ,Shu-Ju Tai ,Chin-Chung Tsai ,Gwo-Dong Chen ,Chen-Wei Jong ,Baw-Jhiune Liu",
                    "year": 2006,
                    "title": "Wristbands as interaction devices: A vision-based interaction space for facilitating full-body learning",
                    "conference": "The 4th International Workshop on Wireless, Mobile and Ubiquitous Technologies in Education",
                    "location": "Athens,Greece"
                },
                {
                    "author": "Y.W. Huang, K.H. Lu, S.Y. Chen and C.C. Liu",
                    "year": 2006,
                    "title": "Graph/Image legend retrieval",
                    "conference": "The International Computer Symposium",
                    "location": "Taipei, Taiwan",
                    "time": "2006"
                },
                {
                    "author": "J.W. Chio, S.Y. Chen, and C.C. Liu",
                    "year": 2005,
                    "month": "August",
                    "title": "Legend extraction for e-Learning video stream",
                    "conference": "IPPR Conference on Computer Vision, Graphics and Image Processing",
                    "location": "Taiwan, R.O.C.",
                    "page": "p. 144",
                    "time": "August 2005"
                },
                {
                    "author": "Lin, T.* , Liu, R., Chen-Chung Liu, Chen, C., Chen, S.",
                    "year": 2005,
                    "title": "Genetic algorithms and silhouette measures applied to microarray data",
                    "conference": "Third Asia-Pacific Bioinformatics Conference",
                    "publisher": "Imperial College Press",
                    "status": "Forthcoming",
                    "time": "2005"
                },
                {
                    "author": "Chen-Chung Liu, Shu-Yuan Tao",
                    "year": 2005,
                    "title": "Bridging the Gap between Students and Computers for Internet Group Learning Activity",
                    "conference": "World Conference on Educational Multimedia, Hypermedia & Telecommunications (ED-Media)",
                    "time": "2005"
                },
                {
                    "author": "Chen-Chung Liu, Ping-Hsing Don, Ren-Zuo You, Baw-Jhiune Liu",
                    "year": 2005,
                    "title": "Portfolio Search Engine Based on Personal Construct System",
                    "conference": "The 5th IEEE International Conference on Advanced Learning Technologies",
                    "time": "2005"
                },
                {
                    "author": "Chen-Chung Liu, Lin-Chuan Kao",
                    "year": 2005,
                    "title": "Handheld Devices with Large Shared Display Groupware: Tools to Facilitate Group Communication in One-to-One Collaborative Learning Activities",
                    "conference": "IEEE International Workshop on Wireless and Mobile Technologies in Education",
                    "page": "128-135",
                    "time": "2005"
                },
                {
                    "author": "Chen-Chung Liu, Shu-Yuan Tao, Jinu-Ning Nee, Baw-Juiune Liu, Gwo-Dong Chen, C. C. Hsu",
                    "year": 2005,
                    "title": "Supporting Activity Awareness for Teams-Games-Tournaments with GSM Network",
                    "conference": "IEEE International Workshop on Wireless and Mobile Technologies in Education",
                    "page": "238-242",
                    "time": "2005"
                },
                {
                    "author": "Yu-Fen Chen, Chen-Chung Liu, Ming-Hung Yu, Yun-Chen Lu, Tak-Wai Chan",
                    "year": 2005,
                    "title": "A Study on Elementary Science Classroom Learning with Wireless Response Devices - Implementing Active and Experiential Learning",
                    "conference": "IEEE International Workshop on Wireless and Mobile Technologies in Education",
                    "page": "96-103",
                    "time": "2005"
                },
                {
                    "author": "Lin, T., Liu, R., Liu, C.C., Chen, C., Chen, S.",
                    "year": 2004,
                    "month": "December",
                    "title": "Reducing the variation of gene expression patterns: a grey model approach applied to microarray data classification",
                    "conference": "2004 International Computer Symposium",
                    "page": "pp. 1198~1204"
                },
                {
                    "author": "Chen-Chung Liu, Baw-Jhiune Liu, Joyce Chunmei Shieh",
                    "year": 2004,
                    "month": "July",
                    "title": "Computer-Mediated Communication with Knowledge Acquisition Techniques",
                    "conference": "The 5th Conference on Information Communication Technologies in Education",
                    "location": "Samos, Greece",
                    "time": "2004"
                },
                {
                    "author": "劉寶鈞，劉晨鐘，李致中，李嘉雄",
                    "year": 2003,
                    "month": "Nov",
                    "title": "基於群體構念結構之群體合作學習系統",
                    "conference": "2003 Cross-Strait Information Technology Workshop",
                    "page": "p7-16",
                    "location": "Taiwan, Chungli",
                    "time": "Nov. 24-25, 2003"
                },
                {
                    "author": "劉晨鐘，董炳信，蔡浚明，宋文彥",
                    "year": 2003,
                    "month": "Sep. 12",
                    "title": "以連結分析法診斷個人概念圖",
                    "conference": "第十四屆物件導向技術及應用研討會",
                    "page": "p513-522",
                    "time": "Sep. 12, 2003"
                },
                {
                    "author": "劉晨鐘, 李嘉雄, 劉寶鈞, 李致中",
                    "year": 2003,
                    "title": "基於個人構念結構之群體合作學習系統",
                    "conference": "International Conference on Computer-Assisted Instruction",
                    "time": "2003"
                },
                {
                    "author": "劉晨鐘, 尤仁佐, 董炳信, 劉寶鈞",
                    "year": 2003,
                    "title": "基於個人構念結構之學生作品探索系統",
                    "conference": "International Conference on Computer-Assisted Instruction",
                    "time": "2003"
                },
                {
                    "author": "劉晨鐘, 鄭乃塵",
                    "year": 2003,
                    "title": "SCORM之學習歷程跨平台應用",
                    "conference": "WISCS 2003網路教學系統平台與內容標準化研討會",
                    "location": "高雄師大"
                },
                {
                    "author": "Liang-Yi Lee, Chen-Chung Liu, Ching-Chi Hsu, Gwo-Dong Chen",
                    "year": 2003,
                    "month": "July",
                    "title": "Using Database Technologies in Building Learning Community to Improve Knowledge Exchange",
                    "conference": "The 3rd IEEE International Conference on Advanced Learning Technologies",
                    "page": "pp.473",
                    "location": "Greece",
                    "time": "9-11, July, 2003"
                },
                {
                    "author": "Chen-Chung Liu, Ping-Hsing Dong, Wei-Chieh Lin, Baw-Jhiune Liu",
                    "year": 2002,
                    "month": "December",
                    "title": "Peer Supports on The Web for Activating The Zone of Proximal Development",
                    "conference": "Internaltional Computer Symposium",
                    "time": "December, 2002"
                },
                {
                    "author": "Chen-Chung Liu, Ping-Hsing Don, Kun-Lin Chen, Baw -Jhiune Liu",
                    "year": 2002,
                    "month": "December 3-6",
                    "title": "Reusing Web Learning Portfolios by Case-Based Reasoning, Technology to Scaffold Problem Solving",
                    "conference": "International Conference on Computers in Education",
                    "location": "Auckland, New Zealand",
                    "time": "3-6 December 2002"
                },
                {
                    "author": "Chen-Chung Liu, Baw -Jhiune Liu, Tzu-An Hui, Jorng-Tzong Horng",
                    "year": 2002,
                    "month": "December 3-6",
                    "title": "Web Based Peer Assessment Using Knowledge Acquisition Techniques: Tools for Supporting Contexture Awareness",
                    "conference": "International Conference on Computers in Education",
                    "location": "Auckland, New Zealand",
                    "time": "3-6 December 2002"
                },
                {
                    "author": "陳國棟, 劉晨鐘",
                    "year": 2002,
                    "month": "Oct. 31",
                    "title": "教學網站學習歷程與學習行為分析標準化之研究",
                    "conference": "ELTA 2002網路學習理論與實務學術研討會",
                    "location": "交通大學",
                    "page": "pp. 169-176",
                    "time": "Oct. 31 2002"
                },
                {
                    "author": "Chen-Chung Liu, Baw-Jhiune Liu, Gwo-Dong Chen, Chin-Yea Wang",
                    "year": 2002,
                    "month": "July 16-20",
                    "title": "Predicting Group Performance by Analyzing Group Role and Dialogue",
                    "conference": "The Ninth International Literacy and Education Research Network Conference on Learning",
                    "location": "Beijing",
                    "time": "July 16-20, 2002"
                },
                {
                    "author": "Chen-Chung Liu, Baw-Jhiune Liu, Gwo-Dong Chen, Chin-Yea Wang",
                    "year": 2002,
                    "month": "July 16-20",
                    "title": "A Web Journal System: A Tool for Learning as Scientific Researchers",
                    "conference": "The Ninth International Literacy and Education Research Network Conference on Learning",
                    "location": "Beijing",
                    "time": "July 16-20, 2002"
                },
                {
                    "author": "劉寶鈞，劉晨鐘，林煒傑",
                    "year": 2001,
                    "month": "Dec 18-19",
                    "title": "應用網路知識庫與學生互助管理支援近側發展區",
                    "conference": "Cross-Strait Information Technology Workshop",
                    "page": "p292-299",
                    "location": "Taiwan, Chungli",
                    "time": "Dec 18-19, 2001"
                },
                {
                    "author": "Chen-Chung Liu, Baw-Jhiune Liu, Gwo-Dong Chen, Kuo-Liang Ou, Ching-Fen Pa",
                    "year": 2001,
                    "month": "June 25-30",
                    "title": "Online assessment decision supports in electronic portfolio systems using data mining technologies",
                    "conference": "ED-MEDIA 2001",
                    "page": "p272-273",
                    "location": "Finland, Tampere",
                    "time": "June 25-30, 2001"
                },
                {
                    "author": "Chen-Chung Liu, Gwo-Dong Chen, Chin-Yeh Wang, Chih-Kai Chang",
                    "year": 2001,
                    "month": "June 25-30",
                    "title": "Student modeling for performance assessment using Bayesian network on web portfolios",
                    "conference": "ED-MEDIA 2001",
                    "page": "p1145-1150",
                    "location": "Finland, Tampere",
                    "time": "June 25-30, 2001"
                },
                {
                    "author": "Gwo-Dong Chen, Chin-Yeh Wang, Chih-Kai Chang, Chen-Chung Liu",
                    "year": 2001,
                    "month": "June 25-30",
                    "title": "A Mentor finder based on student preference and learning status for web-based learning systems",
                    "conference": "ED-MEDIA 2001",
                    "page": "p274-279",
                    "location": "Finland, Tampere",
                    "time": "June 25-30, 2001"
                },
                {
                    "author": "Chia-Jung Chang, Jui-Min Tseng, & Chen-Chung Liu",
                    "year": 2011,
                    "month": "November 28 - December 2",
                    "title": "Applying Recommendation System to Facilitate Exploratory Online Learning - An Empirical Study at Graduate Level",
                    "conference": "19th International Conference on Computers in Education (ICCE2011)",
                    "location": "Chiang Mai, Thailand",
                    "page": "634-638",
                    "time": "November-2 December, 2011"
                },
                {
                    "author": "Chia-Jung Chang, Cheng-Chung Liu, Kuo-Ping Liu",
                    "year": 2011,
                    "month": "July 6-8",
                    "title": "The interaction analysis of web collaborative exploration learning in two scenarios: shared-computer and one-to-one computer",
                    "conference": "Advanced Learning Technologies (ICALT2011)",
                    "location": "Athens, GA",
                    "page": "516-520",
                    "time": "6-8 July, 2011"
                },
                {
                    "author": "Kuo-Ping Liu, Cheng-Chung Liu, Kuo-Chun Hung, Chih-Hsin Huang, Chia-Jung Chang",
                    "year": 2011,
                    "month": "July 6-8",
                    "title": "Implementing a Classroom Oral Reading Platform for Primary EFL Students' Oral Reading Rate",
                    "conference": "Advanced Learning Technologies (ICALT2011)",
                    "location": "Athens, GA",
                    "page": "312-313",
                    "time": "6-8 July, 2011"
                },
                {
                    "author": "Jui-Min Tseng, Chia-Jung Chang, Chen-Chung Liu",
                    "year": 2011,
                    "month": "May 29-31",
                    "title": "A learner-centered enhanced filtering recommendation to support learning from the web of knowledge",
                    "conference": "15th Global Chinese Conference on Computers in Education (GCCCE2011)",
                    "location": "Hangzhou, China",
                    "time": "29-31 May, 2011"
                }
            ]
        }
    ]
}