export const members_103 = [
    {
        imgURL: "/images/members/103/member_ms_10301.jpg",
        name: {
            chinese: "邱秉誠",
            english: "Bing Cheng Chiu"
        },
        email: "monkeychiu@gmail.com",
        spec: "HTML 5, 網頁設計"
    },
    {
        imgURL: "/images/members/103/member_ms_10302.jpg",
        name: {
            chinese: "詹中豪",
            english: "Chung Hao Chan"
        },
        email: "greencarol1105@gmail.com",
        spec: "C#, Processing, Unity3D, Flash, Maya"
    },
    {
        imgURL: "/images/members/103/member_ms_10303.jpg",
        name: {
            chinese: "陳宥齊",
            english: "Yu Chi Chen"
        },
        email: "chaocheng812@gmail.com",
        spec: "Java"
    },
]