export const phd = [
  {
    imgURL: "/images/members/phd/Sen_Hao_Ma.jpg",
    name: {
      chinese: "馬森豪",
      english: "Sen Hao Ma",
    },
    email: "peaceway10608@gmail.com",
    spec: "C, Java, Android",
  },
];
