import React from 'react';
import { useTranslation } from "react-i18next";

import { Box, Typography, Divider } from '@mui/material';
import '../css/style.css'


const Footer = () => {
  const { t } = useTranslation();

  return (
    <Box  class='footer'>
      <Typography gutterBottom>
        <Box mb={2} position="relative">
          <Divider />
          <Box
            position="absolute"
            top="50%"
            left="50%"
            style={{ transform: 'translate(-50%, -50%)' }}
          >
            <p className='hint' style={{backgroundColor: "rgb(250, 250, 244)"}}>{t("component.Footer.contact")}</p>
          </Box>
        </Box>
      </Typography>
      <Typography gutterBottom>
        <p className='hint'>{t("component.Footer.address")}</p>
      </Typography>
      <Typography gutterBottom>
        <p className='hint'>{t("component.Footer.time")}</p>
      </Typography>
    </Box>
  );
};

export default Footer;
