export const members_99 = [
    {
        imgURL: "/images/members/99/Members_ms_9901.jpg",
        name: {
            chinese: "陳中民",
            english: "Zhong-Min Chen"
        },
        email: "p7703012000@yahoo.com.tw",
        spec: "C#, ASP.MET, C"
    },
    {
        imgURL: "/images/members/99/Members_ms_9902.jpg",
        name: {
            chinese: "呂冠賢",
            english: "Lu Kuan-Hsien"
        },
        email: "maxlimitc@gmail.com",
        spec: "C/C++, JAVA, ASP.NET"
    },
    {
        imgURL: "/images/members/99/Members_ms_9903.jpg",
        name: {
            chinese: "張均憶",
            english: "Chang Jun-Yi"
        },
        email: "p7703012000@yahoo.com.tw",
        spec: "ASP.NET, C#, C"
    },
    {
        imgURL: "/images/members/99/Members_ms_9904.jpg",
        name: {
            chinese: "張少源",
            english: "Chang Shao-Yuan"
        },
        email: "mattfarrell.zero3@gmail.com",
        spec: "C, C++, Java, Javascript, HTML 5, CSS"
    }
]