import React from 'react';
import { useTranslation } from 'react-i18next';

import Layout from '../component/Layout';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import { Projects } from './projects/projectList';

const Project = () => {
  const { t } = useTranslation();

  const projects = Projects();

  return (
    <Layout>
      <Box padding={2}>
        <h5 style={{ marginBottom: '-30px' }}>{t("page.Project.title")}</h5>
      </Box>
      <Box padding={5}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '8%' }} align="center"><p>{t("page.Project.column1")}</p></TableCell>
                <TableCell align="center"><p>{t("page.Project.column2")}</p></TableCell>
                <TableCell style={{ width: '10%' }} align="center"><p>{t("page.Project.column3")}</p></TableCell>
                <TableCell style={{ width: '10%' }} align="center"><p>{t("page.Project.column4")}</p></TableCell>
                {/* <TableCell style={{ width: '10%' }} align="center"><p>{t("page.Project.column5")}</p></TableCell> */}
                <TableCell style={{ width: '10%' }} align="center"><p>{t("page.Project.column6")}</p></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects.map((project, index) => (
                <TableRow key={index}>
                  <TableCell style={{ fontFamily: 'GenSanRounded' }} align="center">{project.year}</TableCell>
                  <TableCell style={{ fontFamily: 'GenSanRounded' }} align="center">{project.title}</TableCell>
                  <TableCell style={{ fontFamily: 'GenSanRounded' }} align="center">{project.position}</TableCell>
                  <TableCell style={{ fontFamily: 'GenSanRounded' }} align="center">{project.time}</TableCell>
                  {/* <TableCell style={{ fontFamily: 'GenSanRounded' }} align="center">{project.expenditure}</TableCell> */}
                  <TableCell style={{ fontFamily: 'GenSanRounded' }} align="center">{project.sponsor}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Layout>
  );
}

export default Project;
