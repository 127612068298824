import { useTranslation } from "react-i18next";

export const ScienceEducation = () => {
  const { t } = useTranslation();

  return {
    title: t("page.research.science_education.title"),
    logo: "/images/research/science_education/logo.png",
    ref: {
        title: "Cosci - An interactive, collabrative, and friendly online environment for physics learning",
        link: "https://cosci.tw/",
    },
    description: [
      {
        p: t("page.research.science_education.paragraphs.0")
      },
      {
        p: t("page.research.science_education.paragraphs.1")
      }
    ],
    images: [
      {
        desc: t("page.research.science_education.images.0.desc"),
        imgURL:"/images/research/science_education/design.png"
      },
      {
        desc: t("page.research.science_education.images.1.desc"),
        imgURL: "/images/research/science_education/play.png"
      }
    ]
  }
}
