export const members_101 = [
    {
        imgURL: "/images/members/101/member_ms_1011.jpg",
        name: {
            chinese: "王品卿",
            english: "Piu Ching Wang"
        },
        email: "happydoraemon123@cl.ncu.edu.tw",
        spec: "action script, 網頁設計, 數位教材開發"
    },
    {
        imgURL: "/images/members/101/member_ms_10102.png",
        name: {
            chinese: "李杰駿",
            english: "Chieh Chun Lee"
        },
        email: "whplue07@hotmail.com",
        spec: "JAVA, PYTHON, C 程式語言開發, HTML,Flash網頁開發, ISO27001稽核標準"
    },
    {
        imgURL: "/images/members/101/member_ms_10103.png",
        name: {
            chinese: "陳敬涵",
            english: "Ching Han Chen"
        },
        email: "j10813112@gmail.com",
        spec: "網頁設計"
    }
]