import React from "react";

import { Grid, Typography, Divider } from "@mui/material";
import MemberCard from "./MemberCard";

const MasterGrid = ({ title, members }) => {
    const maxContentLength = Math.max(...members.map((members) => {
        return(
            (Math.ceil(members.name.chinese?.length / 31) || 0) +
            (Math.ceil(members.name.english?.length / 31) || 0) +
            (Math.ceil(members.email?.length / 31) || 0) +
            (Math.ceil(members.workConetent?.length / 31) || 0) +
            (Math.ceil(members.spec?.length / 31) || 0) +
            (Math.ceil(members.officePhone?.length / 31) || 0)
        );
    }))

    const minHeight = (maxContentLength * 21) + 325;
    return (
        <div>
            <Typography gutterBottom>
                <p style={{ fontSize: '20px' }}>{title}</p>
            </Typography>
            <Divider />
            <Grid container spacing={3} padding={2}>
                {members.map((member, index) => (
                    <Grid item key={index}>
                        <MemberCard
                            minHeight={`${minHeight}px`}
                            maxWidth={"280px"}
                            imgURL={member.imgURL}
                            name={member.name}
                            email={member.email}
                            spec={member.spec}
                        />
                    </Grid>
                ))}
            </Grid>
        </div>
    )
}

export default MasterGrid;