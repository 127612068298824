export const members_104 = [
    {
        imgURL: "/images/members/104/member_ms_10401.jpg",
        name: {
            chinese: "張文聰",
            english: "Wen Tsung Chang"
        },
        email: "wtchang@g.ncu.edu.tw",
        spec: "C#, Java, Android, JavaScript"
    },
    {
        imgURL: "/images/members/104/member_ms_10403.jpg",
        name: {
            chinese: "楊宸宇",
            english: "Cheng Yu Yang"
        },
        email: "kevin098318@gmail.com",
        spec: "C, C++, Android, Java"
    },
    {
        imgURL: "/images/members/104/member_ms_10402.jpg",
        name: {
            chinese: "溫采婷",
            english: "Cai Ting Wen"
        },
        email: "leafhere@gmail.com",
        spec: "C++"
    },
]