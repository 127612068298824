export const members_95 = [
    {
        imgURL: "/images/members/95/Members_95_r1.jpg",
        name: {
            chinese: "范姜士燻",
            english: "Shih-Hsun Fan Chiang"
        },
        email: "squall.f@gmail.com",
        spec: "Web programming, PHP, Node.js, MySQL, Javascript"
    }
]