import React from 'react';
import { useTranslation } from 'react-i18next';

import Layout from '../component/Layout';
import JournalList from '../component/JournalList';
import MonographList from '../component/MonographList';
import ConferenceList from '../component/ConferenceList';
import { Box } from '@mui/system';

import { journal } from './publication/Journal';
import { monograph } from './publication/Monograph';
import { conference } from './publication/Conference';


const Publication = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Box padding={2}>
        <h5 style={{marginBottom: '-30px'}}>{t("page.Publication.title1")}</h5>
        <JournalList data={journal} />
      </Box>
      <Box padding={2}>
        <h5 style={{marginBottom: '-20px'}}>{t("page.Publication.title2")}</h5>
        <MonographList data={monograph} />
      </Box>
      <Box padding={2}>
        <h5 style={{marginBottom: '-20px'}}>{t("page.Publication.title3")}</h5>
        <ConferenceList data={conference} />
      </Box>
    </Layout>
  );
}

export default Publication;
