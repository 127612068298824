import React from "react";
import Layout from "../../component/Layout";
import MemberCard from "../../component/MemberCard";
import { Grid } from "@mui/material";

import { RelatedResearcher } from "./info/relatedresearcher";

const Relatedresearcher = () => {
    const relatedresearcher = RelatedResearcher();
    // 找出 relatedresearcher 中資料最多的一筆，計算最大高度
    const maxContentLength = Math.max(...relatedresearcher.map((relatedresearcher) => {
        return (
            (relatedresearcher.name?.length || 0) +
            (relatedresearcher.email?.length || 0) +
            (relatedresearcher.workContent?.length || 0) +
            (relatedresearcher.spec?.length || 0) +
            (relatedresearcher.officePhone?.length || 0) + 
            (relatedresearcher.workIn?.length || 0) + 
            (relatedresearcher.link?.length || 0)
        );
    }));

    // 設定最小高度為所有卡片資料最多的高度
    const minHeight = maxContentLength * 5.5; // 假設每個字元平均高度為 3

    return (
        <Layout>
            <Grid container spacing={3} padding={2}>
                {relatedresearcher.map((relatedresearcher, index) => (
                    <Grid item key={index}>
                        <MemberCard
                            maxWidth = "300px"
                            minHeight={`${minHeight}px`}
                            imgURL = {relatedresearcher.imgURL}
                            name={relatedresearcher.name}
                            title={relatedresearcher.title}
                            workIn={relatedresearcher.workIn}
                        >
                        </MemberCard>
                    </Grid>
                ))}
            </Grid>
        </Layout>
    )
}

export default Relatedresearcher;
