export const members_98 = [
    {
        imgURL: "/images/members/98/Members_ms_9801.png",
        name: {
            chinese: "曾國鴻",
            english: "Kuo-Hung Tseng"
        },
        email: "khtseng@cl.ncu.edu.tw",
        spec: "Object C, C/C++, PHP, JAVA, JSP, Android, 網頁設計"
    },
    {
        imgURL: "/images/members/98/Members_ms_9802.png",
        name: {
            chinese: "曾瑞敏",
            english: "Ruei-Min Tseng"
        },
        email: "tobestmin@hotmail.com",
        spec: "JAVA, VB.NET, C++.NET, MYSQL"
    },
    {
        imgURL: "/images/members/98/Members_ms_9803.png",
        name: {
            chinese: "鄧貴元",
            english: "Kuei-Yuan Teng"
        },
        email: "roger@nl.ncu.edu.tw",
        spec: "C/C++, C#.NET, JAVA"
    },
    {
        imgURL: "/images/members/98/Members_ms_9804.png",
        name: {
            chinese: "賴允涵",
            english: "Yun-Han Lai"
        },
        email: "miniryu2526@hotmail.com",
        spec: "VB.NET, MySQL, ASP.NET, Apache"
    }
]