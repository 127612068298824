import React from 'react';

import '../css/style.css'

import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';
import { Icon } from '@iconify/react';
import { Helmet } from 'react-helmet';

const Cover = () => {
  const { t, i18n } = useTranslation();
  const [languageText, setLanguageText] = React.useState(i18n.language === "zh" ? "En" : "Zh");
  const [languageToolTipText, setLanguageToolTipText] = React.useState(i18n.language === "zh" ? "Change to English Website" : "切換至中文網站");
  
  const handleLanguageTextChange = () => {
    if (i18n.language === "zh") {
      setLanguageText("En");
      setLanguageToolTipText("Change to English Website");
    } else {
      setLanguageText("Zh");
      setLanguageToolTipText("切換至中文網站");
    }
  }

  const handleLanguageChange = () => {
    const targetLanguage = i18n.language === "zh" ? "en" : "zh";
    i18n.changeLanguage(targetLanguage);
    handleLanguageTextChange();
  };

  return (
    <div className='cover'>
      <Helmet>
        <title>{t('component.Cover.title')}</title>
        <meta name="description" content={t('component.Cover.metaContent')} />
      </Helmet>
      <div className='left-half'>
        <h2>{t('component.Cover.welcomeLeft.0')}</h2>
        <h2>{t('component.Cover.welcomeLeft.1')}</h2>
          <p></p>
          <p className='welcome-left'>{t('component.Cover.welcomeLeft.2')}</p>
          <p className='welcome-left'>{t('component.Cover.welcomeLeft.3')}</p>
          <p className='welcome-left'>{t('component.Cover.welcomeLeft.4')}</p>
          <p></p>
          <Link to="/about" style={{ textDecoration: 'none', color: '#fff'}}>
            <Tooltip title={t('component.Cover.enterButtonToolTip')}>
              <IconButton>
                <Icon icon="pepicons-pop:enter-circle-filled" width="50" color='rgb(255,255,255)' />
                <span style={{ color: 'rgb(255,255,255)', fontWeight:'bold', fontFamily: 'GenSanRounded', marginLeft: 10}}>{t('component.Cover.enterButton')}</span>
              </IconButton>
            </Tooltip>
          </Link>
      </div>
      <div className='right-half'>
        <p className='welcome-right'>{t('component.Cover.welcomeRight.0')}</p>
        <p className='welcome-right'>{t('component.Cover.welcomeRight.1')}</p>
        <p className='welcome-right'>{t('component.Cover.welcomeRight.2')}</p>
        <Link to="/" style={{ textDecoration: 'none', color: '#fff'}}>
            <Tooltip title={languageToolTipText}>
              <IconButton 
                style={{position: 'fixed', right: '30px', bottom: '20px', zIndex: 1000}}
                onClick={() => handleLanguageChange()}
              >
                <Icon icon="mdi:earth" width="30" color='rgb(15,76,129)' />
                <span style={{ color: 'rgb(15,76,129)', fontWeight:'bold', fontFamily: 'GenSanRounded', marginLeft: 5}}>{languageText}</span>
              </IconButton>
            </Tooltip>
          </Link>
      </div>
    </div>
  );
}

export default Cover;