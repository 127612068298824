import React from "react";

import Layout from "../../component/Layout";
import MemberCard from "../../component/MemberCard";
import { phd } from "./info/phd";

import { Grid } from "@mui/material";

const PhD = () => {
    // 找出 phd 中資料最多的一筆，計算最大高度
    const maxContentLength = Math.max(...phd.map((phd) => {
        return (
            (phd.name?.length || 0) +
            (phd.email?.length || 0) +
            (phd.workContent?.length || 0) +
            (phd.spec?.length || 0) +
            (phd.officePhone?.length || 0)
        );
    }));

    // 設定最小高度為所有卡片資料最多的高度
    const minHeight = maxContentLength * 5.7; // 假設每個字元平均高度為 3
    return (
        <Layout>
            <Grid container spacing={3} padding={2}>
            {phd.map((phd, index) => (
                    <Grid item key={index}>
                        <MemberCard
                            minHeight={`${minHeight}px`}
                            imgURL={phd.imgURL}
                            name={phd.name}
                            email={phd.email}
                            workContent={phd.workContent}
                            spec={phd.spec}
                        />
                    </Grid>
                ))}
            </Grid>
        </Layout>
    )
}

export default PhD