export const members_102 = [
    {
        imgURL: "/images/members/102/member_ms10201.jpg",
        name: {
            chinese: "王妤",
            english: "Yu Wang"
        },
        email: "fish7588@gmail.com",
        spec: "Java, 網頁設計, Flash"
    },
    {
        imgURL: "/images/members/102/member_ms10202.jpg",
        name: {
            chinese: "陳韋辰",
            english: "Wei Chen Chen"
        },
        email: "william-1107@hotmail.com",
        spec: "C, Flash, 網頁設計"
    }
]