export const members_112 = [
  {
    imgURL: "/images/members/112/member_ms_11201.jpg",
    name: {
      chinese: "王維彤",
      english: "WEI TUNG WANG",
    },
    email: "w.tung.wang@gmail.com",
    spec: "C/C++, C#",
  },
  {
    imgURL: "/images/members/112/member_ms_11202.jpg",
    name: {
      chinese: "李昭逸",
      english: "CHAO YI LEE",
    },
    email: "s1021003@gmail.com",
    spec: "C/C++, Python",
  },
  {
    imgURL: "/images/members/112/member_ms_11204.jpg",
    name: {
      chinese: "陳冠傑",
      english: "KUAN CHIEH CHEN",
    },
    email: "markchen0907@gmail.com",
    spec: "C, Java",
  },
  {
    imgURL: "/images/members/112/member_ms_11205.jpg",
    name: {
      chinese: "鄭宇辰",
      english: "YU CHEN CHENG",
    },
    email: "hsa11555@gmail.com",
    spec: "C, C/C++, Python",
  },
  {
    imgURL: "/images/members/112/member_ms_11206.jpg",
    name: {
      chinese: "黃裕騫",
      english: "YU CHIEN HUANG",
    },
    email: "add36563@gmail.com",
    spec: "C/C++, Python, C#",
  },
];
