import React, { useRef } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Icon } from '@iconify/react';

const BackToTop = () => {
  const topRef = useRef(null);

  const handleBackToTop = () => {
    topRef.current.scrollIntoView({ behavior: 'smooth'});
  };

  return (
    <>
      {/* 按鈕用於回到頂部 */}
      <IconButton onClick={handleBackToTop} style={buttonStyle}>
        <Icon icon="mdi:chevron-up" width={50}/>
      </IconButton>
      
      {/* 隱藏的頂部元素 */}
      <div 
        ref={topRef} 
        style={{ scrollMarginTop: '10vh', height: '0' }} // 確保 topRef 元素不佔空間
      />
    </>
  );
};

// 按鈕樣式
const buttonStyle = {
  position: 'fixed',
  bottom: '20px',
  right: '20px',
  padding: '5px',
  backgroundColor: '#999999', // 按鈕的背景顏色
  color: '#FFFFFF', // 圖標顏色
  border: 'none',
  borderRadius: '50%', //圓形
  width: '50px', // 設置縮小的寬度
  height: '50px', // 設置縮小的高度
  cursor: 'pointer',
  zIndex: 1000,
};

export default BackToTop;
