import { useTranslation } from "react-i18next";

export const ProfessorInfo = () => {
    const { t } = useTranslation();

    return {
        imgURL: "/images/members/prof.png",
        name: t("page.member.info.professor.name"),
        title: t("page.member.info.professor.title"),
        officePhone: "(03)4227151#35316",
        officeLocation: t("page.member.info.professor.officeLocation"),
        email: "ccliu@cl.ncu.edu.tw",
        department: t("page.member.info.professor.department"),
        currentPosition: t("page.member.info.professor.currentPosition", { returnObjects: true }),
        experience: t("page.member.info.professor.experience", { returnObjects: true }),
        education: t("page.member.info.professor.education", { returnObjects: true }),
        academicSpecialties: t("page.member.info.professor.academicSpecialties", { returnObjects: true }),
        awards: t("page.member.info.professor.awards", { returnObjects: true }),
        academicService: [
            {
                title: t("page.member.info.professor.academicService.0"),
                from: "2018",
                to: "."
            },
            {
                title: t("page.member.info.professor.academicService.1"),
                from: "2017",
                to: "."
            },
            {
                title: "Editorial board member, International Journal of Digital Learning Technology",
                from: "2013",
                to: "."
            },
            {
                title: t("page.member.info.professor.academicService.2"),
                from: "2010",
                to: "."
            },
            {
                title: t("page.member.info.professor.academicService.3"),
                from: "2010",
                to: "."
            },
            {
                title: "Executive committee member, Asia-Pacific Society for Computers in Education (APSCE)",
                from: "2010",
                to: "."
            },
            {
                title: "Associate Editor, Research and Practice in Technology Enhanced Learning",
                from: "2010",
                to: "."
            },
            {
                title: t("page.member.info.professor.academicService.4"),
                from: "2019",
                to: "2021"
            },
            {
                title: t("page.member.info.professor.academicService.5"),
                from: "2015",
                to: "2017"
            },
            {
                title: t("page.member.info.professor.academicService.6"),
                from: "2014",
                to: ""
            },
            {
                title: t("page.member.info.professor.academicService.7"),
                from: "2013",
                to: ""
            },
            {
                title: "Program chair of digital classrooms track, AECT International Conference on the Frontier in e-Learning Research, Taichung, Taiwan",
                from: "2013",
                to: ""
            },
            {
                title: "Co-Chair for IEEE TCLT Awards Programme, IEEE technical committee on learning technology",
                from: "2012",
                to: "2016"
            },
            {
                title: "Education Program Chair, APSCE",
                from: "2011",
                to: "2014"
            },
            {
                title: "Program co-chair, IEEE Wireless, Mobile and Ubiquitous Technologies in Education",
                from: "2010",
                to: ""
            },
            {
                title: "Program co-chair, International Conference on Computers in Education, sub-conference of Classroom, Ubiquitous and Mobile Technologies Enhanced Learning",
                from: "2009",
                to: ""
            },
            {
                title: "Review Board, British Journal of Educational Technologies",
                from: "",
                to: ""
            },
            {
                title: "Review Board, Educational Technology and Society",
                from: "",
                to: ""
            },
            {
                title: "Program Committee, The 5th IEEE International Workshop on Wireless, Mobile and Ubiquitous Technologies in Education (WMUTE), Beijing",
                from: "2008",
                to: ""
            },
            {
                title: "Steering Committee, The 2nd Mobile and Ubiquitous Learning Environment (MULE) Workshop, Hong Kong",
                from: "2007",
                to: ""
            },
            {
                title: "Program Committee, The first international workshop on pen-based learning technologies, Catania, Italy",
                from: "2007",
                to: ""
            },
            {
                title: "Program Committee, The 4th IEEE International Workshop on Wireless, Mobile and Ubiquitous Technologies in Education (WMUTE), Athens",
                from: "2006",
                to: ""
            },
            {
                title: "Program Committee, International Conference on Mobile Learning",
                from: "2006",
                to: ""
            },
            {
                title: t("page.member.info.professor.academicService.8"),
                from: "2006",
                to: ""
            },
            {
                title: "Program Committee, International Conference on Mobile Learning",
                from: "2005",
                to: ""
            }
        ]
    }
}
